import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Navbar from "./Navbar";
import Leftside from "./leftPanel.tsx";
import MiddleSide from "./Middle";
import RightSide from "./rightSide";
import Searchleads from "./Searchleads";
import ChartTime from "./chartTime.tsx";
import Tech_support from "./Tech_support";
import UserManagement from "./userManagement";
import Resources from "./Resources";
import Calendar from "./Calendar_page";
import Dashboard from "./Dashboard";
import Sidebar from "./Sidebar.tsx";
import Add_story from "./leads_page_popups/Add_story";
import ManagementContainer from "../src/ManagementPage.js";
import { useSelector, useDispatch } from "react-redux";
import { logoutTimeout } from "../src/logoutTimeout/LogoutTimeout.js";
import { setUser } from "./redux/currentUserState.js";
import Notification_popup_container from "./notification_popup_container";
import SharedLeadPopup from "./notification_popups/Shared_lead.js";
import NotificationBar from "./notificationBar.js";
import io from "socket.io-client";

let api = require("./apiConfig");
const {
  fetchUnassignedLeadsNew,
  getRecentCustomers,
  getUwActions,
  fetchUserData,
  getTasks,
  handleGettingNotifications,
  checkBreak,
} = require("../src/fetches/fetch.js");
api = api.api;

function LeadsPage() {
  const [notification_popup_data, set_notification_popup_data] = useState();
  const [recentCustomers, setRecentCustomers] = useState();
  const jwtToken = Cookies.get("jwt");
  const [allUserData, setAllUserData] = useState();
  const [searchTerm, setSearchTerm] = useState(null);
  const [switchNumber, setSwitchNumber] = useState(7);
  const [currentHouseholdId, setCurrentHouseholdId] = useState(
    Cookies.get("currentHouseholdId")
  );
  const [notificationPanel, toggleNotificationPanel] = useState(false);
  const [userNotifications, setUserNotifications] = useState();
  const [socket, setSocket] = useState();
  const [show, setShow] = useState(false);
  const [currentUserData, setCurrentUserData] = useState();
  const [userIdAndFullName, setUserIdAndFullName] = useState({});
  const [tasks, setTasks] = useState(null);
  const [currentPopup, setCurrentPopup] = useState(null);
  const [toggleAddAnnouncement, setToggleAddAnnouncement] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recentUwActions, setRecentUwActions] = useState();
  const [newLeads, setNewLeads] = useState();
  const [breakStatus, setBreakStatus] = useState("break_off");
  const toggleSB = useSelector((state) => state.firstSlice.toggle);
  const middleComponent = useSelector((state) => state.pageNavigator.page);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!socket) {
      setSocket(
        io("https://webpupsubforsocketio.webpubsub.azure.com", {
          path: "/clients/socketio/hubs/Hub",
        })
      );
    }
    if (socket) {
      socket.on("connect", () => {
        socket.on("sharedLead", (household_ids) => {
          set_notification_popup_data({
            household_id: household_ids,
          });
          setShow(true);
        });
      });
    }
  }, [socket]);

  /*Gets underwriting actions based on userid and updates state */
  const updateUwActions = async () => {
    const response = await getUwActions(Cookies.get("userid"));
    const data = await response.json();
    setRecentUwActions(data);
  };

  /*Gets recent customers based on userid and updates state */
  const handleGetRecentCustomers = async () => {
    const response = await getRecentCustomers(Cookies.get("userid"));
    const data = await response.json();
    setRecentCustomers(data);
  };

  /*Gets current user data based on userid and updates state */
  const getCurrentUserData = async (id) => {
    const response = await fetch(`${api}/api/user?user_id=${id}`);
    const data = await response.json();

    dispatch(setUser(data[0]));
    setCurrentUserData(data);
  };

  /*Sets userId and full name obj state and set all user data state */
  const handleGetUserData = async () => {
    const response = await fetchUserData();
    const data = await response.json();
    setAllUserData(data);
    const userIDFullNameObj = createUserIDFullNameObj(data);
    setUserIdAndFullName(userIDFullNameObj);
  };

  /*Pure function that generates fullname and user_id obj based on data received from api */
  const createUserIDFullNameObj = (data) => {
    let userIDFullNameObj = {};
    data.forEach((user) => {
      userIDFullNameObj[user.id] = user.full_name;
    });

    return userIDFullNameObj;
  };

  /*Gets tasks and updates task by userid */
  const handleGetTasks = async () => {
    const response = await getTasks(Cookies.get("userid"));
    const data = await response.json();
    setTasks(data);
  };

  async function fetchNewNotifications() {
    const response = await handleGettingNotifications(Cookies.get("userid"));

    const data = await response.json();
    setUserNotifications(data);
  }

  useEffect(() => {
    logoutTimeout();
    updateUwActions();
    handleGetRecentCustomers();
    getCurrentUserData(Cookies.get("userid"));
    handleGetUserData();
    handleGetBreakStatus();
  }, [jwtToken]);

  //Checking break when page loads so we can update the state of the navbar button take break
  const handleGetBreakStatus = async () => {
    const response = await checkBreak(Cookies.get("userid"));
    const data = await response.json();
    if (data && data.action === "break_on") {
      setBreakStatus("break_on");
    } else {
      setBreakStatus("break_off");
    }
  };

  /*Timer that fetches new leads and updates new lead pipeline notification */
  useEffect(() => {
    async function fetchNewLeads() {
      const response = await fetchUnassignedLeadsNew();

      const data = await response.json();

      if (data.length) {
        setNewLeads(true);
      } else {
        setNewLeads(false);
      }
    }
    const intervalId = setInterval(() => {
      fetchNewLeads(); // Call the async function inside the interval
    }, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchNewNotifications(); // Call the async function inside the interval
    }, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  /*Dynamic middle component is mutable by using pageNavigator slice in redux*/
  const pageNavigator = {
    lead: (
      <MiddleSide
        currentUserData={currentUserData}
        getTasks={handleGetTasks}
        allUserData={allUserData}
        setCurrentHouseholdId={setCurrentHouseholdId}
        currentHouseholdId={currentHouseholdId}
        api={api}
        userIdAndFullName={userIdAndFullName}
        setRecentCustomers={setRecentCustomers}
        recentCustomers={recentCustomers}
        setSwitchNumber={setSwitchNumber}
        getUwActions={getUwActions}
      />
    ),
    leads: (
      <Searchleads
        allUserData={allUserData}
        api={api}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setSwitchNumber={setSwitchNumber}
        setCurrentHouseholdId={setCurrentHouseholdId}
        currentUserData={currentUserData}
        userIdAndFullName={userIdAndFullName}
        setCurrentPopup={setCurrentPopup}
        currentPopup={currentPopup}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    ),
    pipeline: (
      <ChartTime
        api={api}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        setCurrentHouseholdId={setCurrentHouseholdId}
        currentUserData={currentUserData}
        setSwitchNumber={setSwitchNumber}
      />
    ),
    userManagement: <UserManagement />,
    techSupport: <Tech_support api={api} currentUserData={currentUserData} />,
    resources: <Resources api={api} currentUserData={currentUserData} />,
    calender: <Calendar tasks={tasks} getTasks={handleGetTasks} api={api} />,
    dashboard: (
      <Dashboard
        api={api}
        currentUserData={currentUserData}
        userIdAndFullName={userIdAndFullName}
        setSwitchNumber={setSwitchNumber}
        setCurrentHouseholdId={setCurrentHouseholdId}
      />
    ),
    agentzManagement: (
      <ManagementContainer
        userIdAndFullName={userIdAndFullName}
        currentUserData={currentUserData}
        allUserData={allUserData}
        fetchUserData={handleGetUserData}
        setCurrentHouseholdId={setCurrentHouseholdId}
      />
    ),
  };

  return (
    <div className="main-container ">
      <Notification_popup_container
        show={show}
        setShow={setShow}
        popup={
          <SharedLeadPopup
            popup_data={notification_popup_data}
            setShow={setShow}
            setCurrentHouseholdId={setCurrentHouseholdId}
            set_popup_data={set_notification_popup_data}
          />
        }
      />
      <Navbar
        socket={socket}
        setSocket={setSocket}
        setSwitchNumber={setSwitchNumber}
        allUserData={allUserData}
        userIdAndFullName={userIdAndFullName}
        setCurrentPopup={setCurrentPopup}
        toggleNotificationPanel={toggleNotificationPanel}
        userNotifications={userNotifications}
        notificationPanel={notificationPanel}
        breakStatus={breakStatus}
        setBreakStatus={setBreakStatus}
      />

      <div className="holy-grail ">
        <div>
          <Sidebar
            currentUserData={currentUserData}
            setSwitchNumber={setSwitchNumber}
            setToggleAddAnnouncement={setToggleAddAnnouncement}
            newLeads={newLeads}
            toggleSB={toggleSB}
          />
        </div>

        <div>
          <Leftside
            tasks={tasks}
            getTasks={handleGetTasks}
            setCurrentHouseholdId={setCurrentHouseholdId}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            recentCustomers={recentCustomers}
            setSwitchNumber={setSwitchNumber}
          />
        </div>
        <div className="middle">{pageNavigator[middleComponent]}</div>

        <div>
          <RightSide
            recentUwActions={recentUwActions}
            api={api}
            setSwitchNumber={setSwitchNumber}
            setCurrentHouseholdId={setCurrentHouseholdId}
          />
        </div>
        <div>
          {
            <NotificationBar
              fetchNewNotifications={fetchNewNotifications}
              userNotifications={userNotifications}
              toggleNotificationPanel={toggleNotificationPanel}
              setCurrentHouseholdId={setCurrentHouseholdId}
              notificationPanel={notificationPanel}
            />
          }
        </div>
      </div>

      {toggleAddAnnouncement && (
        <Add_story
          setToggleAddAnnouncement={setToggleAddAnnouncement}
          setSwitchNumber={setSwitchNumber}
          setCurrentHouseholdId={setCurrentHouseholdId}
        />
      )}
    </div>
  );
}

export default LeadsPage;
