import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
const {
  get_payroll,
  getUserCommissions,
  getBonusData,
} = require("./fetches/fetch");

function PayrollCommissionAndBonusTableTotalPay({
  payrollData,
  userIdAndFullName,
  allUserData,
  commissionsData,
  bonusDataAfterCalculation,
}) {
 
  return (
    <table>
      <thead>
        <th>Employee</th>
        <th>Total Hours And Minutes</th>
        <th>Total Pay</th>
        <th>Comissions </th>
        <th>Bonuses</th>
        <th>Total</th>
        <th></th>
      </thead>
      <tbody>
  {payrollData &&
    payrollData.map((userPayrollData, index) => {
      if (!userPayrollData.on_payroll) return null;

      const userId = userPayrollData.user_id;
      const userFullName = userIdAndFullName[userId];

      // Find user data for hourly rate
      const userData = allUserData.find((user) => String(user.id) === userId);
      const hourlyRate = userData?.hourly_rate || 0;

      // Calculate total earnings
      const totalEarnings = (
        userPayrollData.total_hours * hourlyRate +
        (userPayrollData.total_minutes / 60) * hourlyRate
      ).toFixed(2);

      // Find user commission
      const userCommission = commissionsData?.find((user) => user.id === userId);
      const commissionAmount = userCommission?.commission_amount || 0;
      const totalMvrFee = userCommission?.total_mvr_fee || 0;
      const totalClueFee = userCommission?.total_clue_fee || 0;
      const netCommission = (commissionAmount - totalMvrFee - totalClueFee).toFixed(2);

      // Find bonus
      const bonus = bonusDataAfterCalculation?.find((user) => user.user_id === userId)?.bonus || 0;
      const totalPayout = (Number(totalEarnings) + Number(netCommission) + Number(bonus)).toFixed(2);


      return (
        <tr key={index} className="border-bottom">
          <td>{userFullName}</td>
          <td>
            {userPayrollData.total_hours}hrs {userPayrollData.total_minutes}m
          </td>
          <td>${totalEarnings}</td>
          <td>${netCommission}</td>
          <td>${bonus}</td>
          <td>${totalPayout}</td>
        </tr>
      );
    })}
</tbody>
    </table>
  );
}

export default PayrollCommissionAndBonusTableTotalPay;
