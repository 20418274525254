export const insurance_companies = [
    "AIG",
    "Allstate",
    "Allstate Flood",
    "American Bankers Insurance Company",
    "American Integrity Insurance of Florida, Inc.",
    "American Reliable Insurance",
    "AmWins",
    "Auto-Owners Insurance",
    "Auto Owners",
    "Dairyland",
    "Cabrillo Coastal",
    "Citizens Property Insurance Corporation",
    "Chubb",
    "CNA",
    "Edison",
    "Federated National Insurance Company",
    "Florida Family Insurance Company",
    "Florida Peninsula Insurance Company",
    "Foremost Insurance",
    "Frontline Insurance",
    "Geico",
    "GoldenTrust Insurance",
    "Heritage Property & Casualty Insurance Company",
    "Infinity",
    "Jewelry Mutual",
    "Kemper",
    "Liberty Mutual",
    "Mapfre",
    "Monarch",
    "NEXT",
    "National General",
    "Ovation",
    "Progressive",
    "Safety Harbour",
    "Safeco",
    "Security First",
    "Slide",
    "Southern Oak Insurance",
    "Southern Owners",
    "State Farm",
    "Star Casualty",
    "Travelers",
    "Tower Hill Signature Insurance Company",
    "United Auto",
    "United Property and Casualty",
    "Universal Of North America",
    "USAA",
    "The General",
    "The Hartford",
    "AON Flood",
    "Beyond Floods", 
    
  
  ];