import React, { useEffect, useState } from "react";
import { insurance_companies } from "../jsonData/insurance_companies.js";
import { policy_types } from "../jsonData/policy_types";

import { changePageState } from "../redux/pageNavigator.js";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
const {
  getPoliciesByUserId,
  editPolicyAttribute,
  getUnpaidCommission,
  recordCommissionPeriod,
  getCommissionsRecordByDate,
  generateCommissionsStatement,
} = require("../fetches/fetch.js");
function CommissionPolicyPopup({
  setToggleCommissionPopup,
  userIdAndFullName,
  currentUserID,
  setCurrentUserID,
  setCurrentHouseholdId,
}) {
  const [policies, setPolicies] = useState();
  const [premTotal, setPremTotal] = useState();
  const [unpaidCommission, setUnpaidCommission] = useState();
  const [commissions_period_data, set_commissions_period_data] = useState();
  const [loadingCommissionStatement, setLoadingCommissionStatement] =
    useState(false);
  const [toDate, setToDate] = useState(() => {
    const endOfMonth = new Date();
    // Move to the next month
    endOfMonth.setDate(0); // Set to the last day of the previous month
    // Set time to the end of the day

    return endOfMonth;
  });
  const [fromDate, setFromDate] = useState(() => {
    const startOfMonth = new Date();
    startOfMonth.setMonth(startOfMonth.getMonth() - 1);
    startOfMonth.setDate(1); // Set to the first day of the month
    startOfMonth.setHours(0, 0, 0, 0);
    return startOfMonth;
  });
  
  const [sales_type, set_sales_type] = useState("");
  const [computedItemsTotal, setComputedItemsTotal] = useState();
  const [numberOfPoliciesTotal, setNumberOfPoliciesTotal] = useState();
  const [baseCommission, setBaseComission] = useState();
  const [pastUnapidCommissionTotal, setPastUnpaidCommissionTotal] = useState();
  const dispatch = useDispatch();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleGetCommisionsRecordData = async () => {
    const userBeingViewed =
      currentUserID !== "" ? userIdAndFullName[currentUserID] : "All Users";
    const response = await getCommissionsRecordByDate(
      fromDate,
      toDate,
      userBeingViewed
    );
    const data = await response.json();
    set_commissions_period_data(data);
  };

  const handleGetPolicies = async (user_id, fromDate, toDate, sales_type) => {
    const response = await getPoliciesByUserId(
      user_id,
      fromDate,
      toDate,
      sales_type,
      true
    );
    const data = await response.json();
    setPolicies(data);
  };

  const handleGetUnpaidComission = async (fromDate, user_id) => {
    const response = await getUnpaidCommission(fromDate, user_id);
    const data = await response.json();
    setUnpaidCommission(data);
  };

  useEffect(() => {
    handleGetUnpaidComission(fromDate, currentUserID);
  }, [fromDate, currentUserID, policies]);
  useEffect(() => {
    handleGetPolicies(currentUserID, fromDate, toDate, sales_type);
  }, [toDate, fromDate, sales_type, currentUserID]);

  const handleGeneratingCommissionStatement = async () => {
    const periodStart = fromDate.toISOString().slice(0, 10).replace("T", " ");
    const periodEnd = toDate.toISOString().slice(0, 10).replace("T", " ");
    const userBeingViewed =
      currentUserID !== "" ? userIdAndFullName[currentUserID] : "All Users";
    const response = await generateCommissionsStatement(
      periodStart,
      periodEnd,
      userBeingViewed
    );
    const url = await response.json();

    window.location.href = url;
    setLoadingCommissionStatement(false);
    if (response.status === 500) {
      return alert("Error generating Commissions statement");
    }
  };

  useEffect(() => {
    handleGetCommisionsRecordData();
  }, [toDate, fromDate, policies]);
  useEffect(() => {
    
    computeTotal();
    if (policies) {
      calculateBaseCommissionTotal(policies);
      
    }
  }, [policies, unpaidCommission]);

  const computeTotal =  () => {
    if (policies && unpaidCommission) {
      setPremTotal(
        policies
          .reduce(
            (total, policy) =>
              policy.comp_status === "Ready To Pay"
                ? total + (policy.commissionable_premium || 0)
                : total + 0,
            0
          )
          .toFixed(2)
      );

      setComputedItemsTotal(
        policies.reduce(
          (total, policy) =>
            policy.comp_status === "Ready To Pay"
              ? total + (policy.items || 0)
              : total + 0,
          0
        )
      );
      setPastUnpaidCommissionTotal(
        unpaidCommission.reduce(
          (total, policy) => total + policy.total_commission_amount,
          0
        )
      );

      setNumberOfPoliciesTotal(policies.length);
    }
  };
  const handleAttributeUpdate = async (policy_id, attribute, data) => {
    const response = await editPolicyAttribute(policy_id[0], attribute, data);
    if (response.status === 200) {
      return await handleGetPolicies(
        currentUserID,
        fromDate,
        toDate,
        sales_type
      );
    }
  };
  const viewLeadPage = (household_id) => {
    Cookies.set("currentHouseholdId", household_id);
    setCurrentHouseholdId(household_id);
    dispatch(changePageState("lead"));
  };

  const handleStatusChange = async (policy_id, attribute, eventValue) => {
    await handleAttributeUpdate(policy_id, attribute, eventValue);
    if (eventValue !== "Active") {
      await handleAttributeUpdate(policy_id, "comp_status", "Do Not Pay");
    } else {
      await handleAttributeUpdate(policy_id, "comp_status", "Ready To Pay");
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const createSnapShotOfCommissionsData = () => {
    const policiesData = policies.map((policy) => ({ 
      policy_id: policy.policy_id[0],
      full_name: policy.full_name,
      policy_id_two: policy.policy_id_two,
      policy_type: policy.policy_type,
      date_bound: policy.date_created[0],
      user_created_id: policy.user_created_id,
      company: policy.company,
      effective_date: policy.effective_date,
      sales_type: policy.sales_type,
      status: policy.status[0],
      commissionable_premium: policy.commissionable_premium || 0,
      total_commission_amount: policy.total_commission_amount || 0,
      mvr_fee:
        policy.company === "National General"
          ? policy.number_of_drivers * 10
          : 0,
      clue_fee: policy.company === "National General" ? 3 : 0,
      line_of_business:
        policy.organization_household_id !== null ? "Commercial" : "Personal",
      branch: "II MIE INC",
      comp_status: policy.comp_status,
      multiline: policy["multiline "] === true ? 1 : 0,
      monoline: policy.monoline === true ? 1 : 0,
      uw_action:
        policy.comp_status === "Do Not Pay" || policy.status[0] !== "Active"
          ? "Yes"
          : "No",
      periodStart: fromDate?.toISOString().split("T")[0],
      periodEnd: toDate?.toISOString().split("T")[0],
      userBeingViewed:
        currentUserID !== "" ? userIdAndFullName[currentUserID] : "All Users",
    }));

    return policiesData;

  };

  const handleRecordingCommissionsData = async () => {
    const commissions_obj = createSnapShotOfCommissionsData();
    
    const resposne = await recordCommissionPeriod(commissions_obj);
    if (resposne.status === 200) {
      return handleGetCommisionsRecordData();
    }
    return alert("Error recording commissions data");
  };

  const calculateBaseCommissionTotal = (policies) => {
    const baseCommissionSum = policies.reduce(
      (acc, curr) =>
        curr.comp_status === "Ready To Pay"
          ? acc + curr.total_commission_amount
          : acc + 0,
      0
    );
    setBaseComission(baseCommissionSum);
    return;
  };

  return (
    <>
      <div className="background-color-full-dark border-radius-10px popup z-index ">
        <div className="black-background  flex-across-space-between align-itmes-center  border-radius-top-10px  ">
          <h3 className="black-background">
            Commissions Production Month:{" "}
            {new Date().getMonth() - 1 === -1
              ? "December" + " "
              : monthNames[new Date().getMonth() - 1]}
          </h3>
          <h3 className="black-background margin-left-neg-20">
            {" "}
            Compensation Month: {monthNames[new Date().getMonth()]}
          </h3>

          <button
            type="button"
            className="popup-exit-button"
            onClick={() => setToggleCommissionPopup(false)}
          >
            ❌
          </button>
        </div>

        <div className=" padding-1px light-dark-background height-80vh  ">
          <div className="flex-across light-dark-background align-items-center flex-across-space-between max-width">
            <div className="flex-column-no-ho-centered">
              <p className="light-dark-background">
                <h3 className="light-dark-background">Comm Prem Total:</h3>{" "}
              </p>
              <p className="light-dark-background">${premTotal}</p>
            </div>
            <div className="flex-column-no-ho-centered">
              <p className="light-dark-background">
                <h3 className="light-dark-background">Items Total:</h3>
              </p>
              <p className="light-dark-background">{computedItemsTotal}</p>
            </div>
            <div className="flex-column-no-ho-centered">
              <p className="light-dark-background">
                <h3 className="light-dark-background">Number Of Policies:</h3>
              </p>
              <p className="light-dark-background">{numberOfPoliciesTotal}</p>
            </div>
            <div className="flex-column-no-ho-centered">
              <p className="light-dark-background">
                <h3 className="light-dark-background">
                  Base Commission Total:
                </h3>
              </p>
              <p className="light-dark-background">
                ${baseCommission ? baseCommission.toFixed(2) : "N/A"}
              </p>
            </div>
            <div className="flex-column-no-ho-centered">
              <p className="light-dark-background">
                <h3 className="light-dark-background">
                  Past Unpaid Commission Total
                </h3>
              </p>
              <p className="light-dark-background">
                $
                {pastUnapidCommissionTotal
                  ? pastUnapidCommissionTotal.toFixed(2)
                  : "0"}
              </p>
            </div>
            <div className="flex-column-no-ho-centered">
              <p className="light-dark-background">
                <h3 className="light-dark-background"> Total</h3>
              </p>
              <p className="light-dark-background">
                ${(pastUnapidCommissionTotal + baseCommission).toFixed(2)}
              </p>
            </div>
            <div className="light-dark-background display-flex flex-column-no-ho-centered  ">
              <h3 className="light-dark-background ">
                <span className="light-dark-background ">From: </span>
              </h3>
              <input
                className="light-dark-background  "
                type="date"
                name="from "
                defaultValue={fromDate.toISOString().split("T")[0]}
                onChange={(e) => setFromDate(new Date(e.target.value))}
              />
            </div>
            <div className="light-dark-background display-flex flex-column-no-ho-centered  ">
              <b className="light-dark-background">
                <span className="light-dark-background margin-left-5 ">
                  To:
                </span>
              </b>
              <input
                className="light-dark-background margin-left-5"
                type="date"
                name="to"
                defaultValue={toDate.toISOString().split("T")[0]}
                onChange={(e) => setToDate(new Date(e.target.value))}
              />
            </div>
            <div>
              <h3 className="light-dark-background ">
                <label className="light-dark-background ">Sales Type</label>
              </h3>
              <select
                className="light-dark-background green-submit-btn padding-left-none"
                defaultValue={sales_type}
                onClick={(e) => set_sales_type(e.target.value)}
              >
                <option value="">All</option>
                <option value="New Business">New Business</option>
                <option value="Rewrite">Rewrite</option>
                <option value="Cross Sale">Cross Sale</option>
                <option value="Cancel Rewrite">Cancel Rewrite</option>
                <option value="AOR Change">AOR Change</option>
              </select>
            </div>
            <div className="light-dark-background ">
              <h3 className="light-dark-background ">Users</h3>
              <select
                className="light-dark-background  green-submit-btn padding-left-none"
                onClick={(e) => setCurrentUserID(e.target.value)}
              >
                <option value={""}>All Users</option>
                {userIdAndFullName &&
                  Object.keys(userIdAndFullName).map((id) => (
                    <option value={id} selected={id === currentUserID}>
                      {userIdAndFullName[id]}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          <div className="commissions-popup margin-top-10px font-small">
            <table className="th-style ">
              <thead className="max-width">
                <th>Primary Driver Name</th>
                <th>Policy Number</th>
                <th>Policy Type</th>
                <th>Date Bound</th>
                <th>Producer</th>
                <th>Company</th>
                <th>Effective Date</th>
                <th>Sales Type</th>
                <th>Status</th>
                <th>Comm Prem</th>
                <th>Percentage</th>
                <th>Commission Per Policy</th>
                <th>MVR Fee</th>
                <th>Clue Fee</th>
                <th>Total Commission Amount</th>
                <th>Line of Business</th>
                <th>Branch</th>
                <th>Comp. Status</th>
                <th>Multiline</th>
                <th>Monoline</th>
                <th>UW Action</th>
              </thead>
              <tbody id="search-results_table_body">
                {policies &&
                  policies.map((policy) => (
                    <>
                      <tr>
                        <td>{policy.full_name}</td>
                        <td>
                          <div
                            onClick={(e) =>
                              copyToClipboard(policy.policy_id_two)
                            }
                          >
                            {policy.policy_id_two}
                          </div>
                          <div>
                            <u
                              className="color-red cursor-pointer"
                              onClick={() =>
                                viewLeadPage(policy.household_id[0])
                              }
                            >
                              View
                            </u>
                          </div>
                        </td>
                        <td>
                          <select
                            value={policy.policy_type}
                            className="max-width"
                            onChange={(e) =>
                              handleAttributeUpdate(
                                policy.policy_id,
                                "policy_type",
                                e.target.value
                              )
                            }
                          >
                            {policy_types &&
                              policy_types.map((policy_type) => (
                                <option
                                  value={policy_type}
                                  selected={policy.policy_type === policy_type}
                                >
                                  {policy_type}
                                </option>
                              ))}
                          </select>
                        </td>
                        <td>
                          {" "}
                          {new Date(policy.date_created[0]).toLocaleDateString(
                            "en-US",
                            { timeZone: "UTC" }
                          )}
                        </td>
                        <td>
                          {userIdAndFullName && (
                            <select
                              className="max-width"
                              value={policy.user_created_id || ""}
                              onChange={(e) =>
                                handleAttributeUpdate(
                                  policy.policy_id,
                                  "user_created_id",
                                  e.target.value
                                )
                              }
                            >
                              {Object.keys(userIdAndFullName).map((id) => (
                                <option
                                  value={id}
                                  selected={
                                    policy.user_created_id &&
                                    policy.user_created_id === id
                                  }
                                >
                                  {userIdAndFullName[id]}
                                </option>
                              ))}
                            </select>
                          )}
                        </td>
                        <td>
                          <select
                            className="max-width"
                            value={policy.company}
                            onChange={(e) =>
                              handleAttributeUpdate(
                                policy.policy_id,
                                "company",
                                e.target.value
                              )
                            }
                          >
                            {insurance_companies &&
                              insurance_companies.map((company_name) => (
                                <option value={company_name}>
                                  {company_name}
                                </option>
                              ))}
                          </select>
                        </td>
                        <td>
                          {new Date(policy.effective_date).toLocaleDateString(
                            "en-US",
                            { timeZone: "UTC" }
                          )}
                        </td>
                        <td>
                          <select
                            className="max-width"
                            onChange={(e) =>
                              handleAttributeUpdate(
                                policy.policy_id,
                                "sales_type",
                                e.target.value
                              )
                            }
                          >
                            <option
                              value="New Business"
                              selected={"New Business" === policy.sales_type}
                            >
                              New Business
                            </option>
                            <option
                              value="Rewrite"
                              selected={"Rewrite" === policy.sales_type}
                            >
                              Rewrite
                            </option>
                            <option
                              value="Cancel Rewrite"
                              selected={"Cancel Rewrite" === policy.sales_type}
                            >
                              Cancel Rewrite
                            </option>
                            <option
                              value="Cross Sale"
                              selected={"Cross Sale" === policy.sales_type}
                            >
                              Cross Sale
                            </option>
                            <option
                              value="AOR Change"
                              selected={"AOR Change" === policy.sales_type}
                            >
                              AOR Change
                            </option>
                          </select>
                        </td>
                        <td>
                          <select
                            value={policy.status[0]}
                            className="max-width"
                            onChange={(e) =>
                              handleStatusChange(
                                policy.policy_id,
                                "status",
                                e.target.value
                              )
                            }
                          >
                            <option
                              value="Active"
                              selected={"Active" === policy.status[0]}
                            >
                              Active
                            </option>
                            <option
                              value="Cancelled"
                              selected={"Cancelled" === policy.status[0]}
                            >
                              Cancelled
                            </option>
                            <option
                              value="Pending Cancel"
                              selected={"Pending Cancel" === policy.status[0]}
                            >
                              Pending Cancel
                            </option>
                            <option
                              value="Terminated"
                              selected={"Terminated" === policy.status[0]}
                            >
                              Terminated
                            </option>
                          </select>
                        </td>
                        <td>
                          <div className="flex align-items-center light-dark-background">
                            <input
                              type="text"
                              placeholder={
                                policy.commissionable_premium
                                  ? "$" +
                                    policy.commissionable_premium.toFixed(2)
                                  : null
                              }
                              className="max-width box-border-2"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  // Correctly access the key property
                                  handleAttributeUpdate(
                                    policy.policy_id,
                                    "commissionable_premium",
                                    e.target.value
                                  );
                                  e.target.value = "";
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td>{policy.percentage * 100}%</td>
                        <td>
                          $
                          {policy.commission_per_policy
                            ? policy.commission_per_policy.toFixed(2)
                            : "N/A"}
                        </td>
                        <td>
                          {policy.fees.mvr_fee * policy.number_of_drivers}
                        </td>
                        <td>{policy.fees.clue_fee}</td>
                        <td>
                          $
                          {policy.total_commission_amount
                            ? policy.total_commission_amount.toFixed(2)
                            : "N/A"}
                        </td>
                        <td>
                          {policy.organization_household_id !== null
                            ? "Commercial"
                            : "Personal"}{" "}
                        </td>

                        <td>II MIE INC</td>
                        <td>{policy?.comp_status}</td>
                        <td>{policy["multiline "] === true ? "1" : "0"}</td>
                        <td>{policy.monoline === true ? "1" : "0"}</td>
                        <td>
                          <select
                            onChange={(e) =>
                              handleAttributeUpdate(
                                policy.policy_id,
                                "comp_status",
                                e.target.value
                              )
                            }
                          >
                            <option
                              selected={
                                policy.uw_action_id !== null ||
                                policy.comp_status === "Do Not Pay"
                              }
                              value="Do Not Pay"
                            >
                              Yes
                            </option>
                            <option
                              selected={
                                policy.uw_action_id === null ||
                                policy.comp_status === "Ready To Pay"
                              }
                              value="Ready To Pay"
                            >
                              No
                            </option>
                          </select>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
            </table>
          </div>
          <label className="light-dark-background margin-top-10px">
            <h3 className="light-dark-background">Past Unpaid Commission</h3>
          </label>
          <div className="commissions-popup margin-top-10px font-small ">
            <table className="th-style ">
              <thead>
                <th>Primary Driver Name</th>
                <th>Policy Number</th>
                <th>Policy Type</th>
                <th>Date Bound</th>
                <th>Producer</th>
                <th>Company</th>
                <th>Effective Date</th>
                <th>Sales Type</th>
                <th>Status</th>
                <th>Comm Prem</th>
                <th>Percentage</th>
                <th>Commission Per Policy</th>
                <th>MVR Fee</th>
                <th>Clue Fee</th>
                <th>Total Commission Amount</th>
                <th>Line of Business</th>
                <th>Location</th>
                <th>Comp. Status</th>
                <th>Multiline</th>
                <th>Monoline</th>
                <th>UW Action</th>
              </thead>

              <tbody id="search-results_table_body">
                {unpaidCommission &&
                  unpaidCommission.map((commission) => (
                    <tr className="cursor-pointer hover-secondary-color">
                      <td>{commission.full_name}</td>
                      <td>{commission.policy_id_two}</td>

                      <td>
                        <select
                          className="max-width"
                          onChange={(e) =>
                            handleAttributeUpdate(
                              commission.policy_id,
                              "policy_type",
                              e.target.value
                            )
                          }
                        >
                          {policy_types &&
                            policy_types.map((policy) => (
                              <option
                                value={policy}
                                selected={policy === commission.policy_type}
                              >
                                {policy}
                              </option>
                            ))}{" "}
                        </select>
                      </td>
                      <td>
                        {new Date(
                          commission.date_created[0]
                        ).toLocaleDateString("en-US", { timeZone: "UTC" })}
                      </td>
                      <td>
                        <select
                          className="max-width"
                          onChange={(e) =>
                            handleAttributeUpdate(
                              commission.policy_id,
                              "user_created_id",
                              e.target.value
                            )
                          }
                        >
                          {userIdAndFullName &&
                            Object.keys(userIdAndFullName).map((id) => (
                              <option
                                value={id}
                                selected={commission.user_created_id === id}
                              >
                                {userIdAndFullName[id]}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td>
                        <select
                          className="max-width"
                          onChange={(e) =>
                            handleAttributeUpdate(
                              commission.policy_id,
                              "company",
                              e.target.value
                            )
                          }
                        >
                          {insurance_companies &&
                            insurance_companies.map((company) => (
                              <option
                                value={company}
                                selected={company === commission.company}
                              >
                                {company}
                              </option>
                            ))}
                        </select>
                      </td>
                      <td>
                        {new Date(commission.effective_date).toLocaleDateString(
                          "en-US",
                          { timeZone: "UTC" }
                        )}
                      </td>
                      <td>
                        <select
                          className="max-width"
                          onChange={(e) =>
                            handleAttributeUpdate(
                              commission.policy_id,
                              "sales_type",
                              e.target.value
                            )
                          }
                        >
                          <option
                            value="New Business"
                            selected={commission.sales_type === "New Business"}
                          >
                            New Business
                          </option>
                          <option
                            value="Rewrite"
                            selected={commission.sales_type === "Rewrite"}
                          >
                            Rewrite
                          </option>
                          <option
                            value="Cancel Rewrite"
                            selected={
                              commission.sales_type === "Cancel Rewrite"
                            }
                          >
                            Cancel Rewrite
                          </option>
                          <option
                            value="Cross Sale"
                            selected={commission.sales_type === "Cross Sale"}
                          >
                            Cross Sale
                          </option>
                          <option
                            value="Cross Sale"
                            selected={commission.sales_type === null}
                          >
                            None
                          </option>
                        </select>
                      </td>
                      <td>
                        <select
                          className="max-width"
                          onChange={(e) =>
                            handleStatusChange(
                              commission.policy_id,
                              "status",
                              e.target.value
                            )
                          }
                        >
                          <option
                            value="Active"
                            selected={
                              commission.status &&
                              commission.status[0] === "Active"
                            }
                          >
                            Active
                          </option>
                          <option
                            value="Cancelled"
                            selected={
                              commission.status &&
                              commission.status[0] === "Cancelled"
                            }
                          >
                            Cancelled
                          </option>
                          <option
                            value="Pending Cancel"
                            selected={
                              commission.status &&
                              commission.status[0] === "Pending Cancel"
                            }
                          >
                            Pending Cancel
                          </option>
                          <option
                            value="Terminated"
                            selected={
                              commission.status &&
                              commission.status[0] === "Terminated"
                            }
                          >
                            Terminated
                          </option>
                        </select>
                      </td>
                      <td>
                        {" "}
                        <div className="flex align-items-center light-dark-background">
                          <input
                            type="text"
                            placeholder={
                              commission.commissionable_premium
                                ? "$" +
                                  commission.commissionable_premium.toFixed(2)
                                : null
                            }
                            className="max-width box-border-2"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                // Correctly access the key property
                                handleAttributeUpdate(
                                  commission.policy_id,
                                  "commissionable_premium",
                                  e.target.value
                                );
                              }
                            }}
                          />
                        </div>
                      </td>
                      <td>{commission.percentage * 100}%</td>
                      <td> {commission.commission_per_policy.toFixed(2)}</td>
                      <td>
                        {commission.fees.mvr_fee * commission.number_of_drivers}
                      </td>
                      <td>{commission.fees.clue_fee}</td>
                      <td>{commission.total_commission_amount.toFixed(2)}</td>
                      <td>
                        {commission.organization_household_id !== null
                          ? "Commercial"
                          : "Personal"}
                      </td>
                      <td>{commission.address_line_2}</td>
                      <td>{commission?.comp_status}</td>
                      <td>{commission["multiline "] === true ? "1" : "0"}</td>
                      <td>{commission.monoline === true ? "1" : "0"}</td>
                      <td>
                        <select
                          onChange={(e) =>
                            handleAttributeUpdate(
                              commission.policy_id,
                              "comp_status",
                              e.target.value
                            )
                          }
                        >
                          <option
                            selected={
                              commission.uw_action_id !== null ||
                              commission.comp_status === "Do Not Pay"
                            }
                            value="Do Not Pay"
                          >
                            Yes
                          </option>
                          <option
                            selected={
                              commission.uw_action_id === null ||
                              commission.comp_status === "Ready To Pay"
                            }
                            value="Ready To Pay"
                          >
                            No
                          </option>
                        </select>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="margin-top-10px light-dark-background">
            {commissions_period_data &&
            commissions_period_data?.length === 0 ? (
              <button
                type="button"
                className="regularTextInput"
                onClick={() => handleRecordingCommissionsData()}
              >
                Close Commission Period
              </button>
            ) : (
              <button
                type="button"
                className="regularTextInput"
                onClick={() => {
                  setLoadingCommissionStatement(true);
                  handleGeneratingCommissionStatement();
                }}
              >
                {loadingCommissionStatement === true
                  ? "Preparing commission statement..."
                  : "Generate Commission Statement"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CommissionPolicyPopup;
