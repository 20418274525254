import React, { useEffect, useState } from 'react';
import Quotes from './Quotes.js'
import Cookies from 'js-cookie';
import Documents from './Documents'
import Underwriting from './uwaction'
import Policies from './Policies'
import Notes from './Notes'
import UnderwritingActionpopup from './underwriting_action_popups/UnderwritingActionpopup';
import SelectPolicyTypePopup from './policy_popups/SelectPolicyTypePopup';
import AddMotorcyclePolicyPopup from './policy_popups/AddMotorcyclePolicyPopup';
import AddAutomobilePolicyPopup from './policy_popups/AddAutomobilePolicyPopup';
import AddNote from './notes_popups/AddNote';
import AddDocumentPopup from './documents_popups/AddDocumentPopup';
import DeletedocumentPopup from './documents_popups/DeletedocumentPopup';
import EditDocumentPopup from './documents_popups/EditDocumentPopup';
import General_policy_popup from './policy_popups/General_policy_popup.js';
import AddQuotePopup from '../src/quote_popup/addQuotePopup.js'
import { insurance_companies } from '../src/jsonData/insurance_companies.js';
import LeadsTab from "./LeadsTab.js";
import LeadsTabPopup from './leadsTabPopup/LeadsTabPopup.js';
const {handleGettingSharedLeads } = require("./fetches/fetch.js");


function TabSection({getHouseholdData, fn, quotes, setQuotes, notes, fetchNotes, householdData, driverData, api, setActiveComponentIndex, activeComponentIndex, currentUserData, userIdAndFullName, vehicleData, setSwitchNumber, setCurrentHouseholdId, copyToClipboard }) {
  const [policyType, setPolicyType] = useState();
  const userId = Cookies.get('userid');
  const jwtToken = Cookies.get('jwt');
  const currentHouseholdId = Cookies.get('currentHouseholdId');
  const [usersData, setUsersData] = useState(null);
  const [policies, setPolicies] = useState();
  const [documentData, setDocumentData] = useState();
  const [uwActionRequest, setUwActionRequest] = useState(null);
  
  const [currentPopup, setCurrentPopup] = useState(null);


  const [sharedLeads, setSharedLeads] = useState();

  const getSharedLeads = async (household_id) => { 
    const response = await handleGettingSharedLeads(household_id);
    const data = await response.json();
    setSharedLeads(data)
  }


  useEffect(() => { 
   
     fn();
   
  }, [])


  const fetchUwActions = async() => { 
    fetch(`${api}/api/uw_action_request?userid=${userId}&household_id=${Cookies.get('currentHouseholdId')}&role=${currentUserData[0].role}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${jwtToken}`
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      
      setUwActionRequest(data);
      
    })
    .catch(error => {
      // Handle any errors
      console.error('There was a problem with your fetch operation:', error);
    });
  
  }

  


  const fetchDocs = async(doc_type) => { 
    let response;
    if(doc_type){ 
       response = await fetch(`${api}/api/documents?household_id=${currentHouseholdId}&doc_type=${doc_type}`)
    }else { 
      response = await fetch(`${api}/api/documents?household_id=${currentHouseholdId}`)
    }
    const data = await response.json();
    setDocumentData(data);
   }


   async function fetchData() {
    try {
      const response = await fetch(`${api}/api/household/${currentHouseholdId}/policies`, {
        headers: {
          Authorization: jwtToken
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const fetchedPolicies = await response.json();
      setPolicies(fetchedPolicies);
      document.querySelectorAll('popup')
    } catch (error) {
      console.error('Fetch error:', error);
      setPolicies(null);
    }
  }
  

  const toggleComponent = (index) => {
    setActiveComponentIndex(index);
  };

  


const togglePopUpById = (id) => { 
  const togglePopUpName = document.getElementById(id);
  togglePopUpName.hidden = !togglePopUpName.hidden;
}





const POST_POLICY = async (dataObj) => {
  if(Cookies.get('jwt')){ 
  const response = await fetch(`${api}/api/household/${currentHouseholdId}/policy`, {
          method: "POST",
          headers: {
              "Authorization": Cookies.get('jwt'),
              "Content-Type": "application/json" // Specify JSON content type
          },
          body: JSON.stringify(dataObj) // Convert object to JSON string
      });

      return response;
    }else { 
      window.location.href = '/'
    }
  }


  

  useEffect(() => {
    
    fetchData();
    if (jwtToken && currentHouseholdId) {
      fetch(`${api}/api/users/all`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${jwtToken}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        
        setUsersData(data);
      })
      .catch(error => {
        // Handle any errors
        console.error('There was a problem with your fetch operation:', error);
      });
    } else {
      console.error('jwtToken or currentHouseholdId is missing');
    }
  }, [jwtToken, currentHouseholdId]);
  
  

  const popups = [ 
    <UnderwritingActionpopup  setCurrentHouseholdId= {setCurrentHouseholdId} setSwitchNumber = {setSwitchNumber} usersData = {usersData} policies = {policies} setCurrentPopup= {setCurrentPopup} api = {api} fetchUwActions = {fetchUwActions} />,
    <SelectPolicyTypePopup setCurrentPopup={setCurrentPopup} setPolicyType = {setPolicyType} />, 
    <AddMotorcyclePolicyPopup driverData = {driverData} vehicleData = {vehicleData} setCurrentPopup={setCurrentPopup} insurance_companies= {insurance_companies}  POST_POLICY= {POST_POLICY} currentHouseholdId={currentHouseholdId} fetchData = {fetchData}  /> ,
    <AddAutomobilePolicyPopup householdData= {householdData} userIdAndFullName={userIdAndFullName} driverData = {driverData} vehicleData = {vehicleData} setCurrentPopup={setCurrentPopup} insurance_companies ={insurance_companies} POST_POLICY= {POST_POLICY} currentHouseholdId={currentHouseholdId} fetchData = {fetchData}/>,
    "string", 
    "string", 
    <AddNote setCurrentPopup={setCurrentPopup} POST_POLICY={POST_POLICY} fetchData= {fetchData} api = {api} currentHouseholdId={currentHouseholdId} toggleComponent= {toggleComponent} fetchNotes= {fetchNotes}/>,
    <AddDocumentPopup api = {api} currentHouseholdId = {currentHouseholdId} fetchDocs = {fetchDocs} policies = {policies} setCurrentPopup={setCurrentPopup} fetchData={fetchData}/>,
    <DeletedocumentPopup api = {api}  documentData = {documentData} setCurrentPopup= {setCurrentPopup} fetchDocs = {fetchDocs} />,
    <EditDocumentPopup setCurrentPopup={setCurrentPopup} documentData = {documentData} fetchDocs = {fetchDocs} api = {api} policies = {policies} />,
    "string",
    <General_policy_popup driverData = {driverData} setCurrentPopup = {setCurrentPopup}  insurance_companies= {insurance_companies} POST_POLICY = {POST_POLICY} fetchData={fetchData} policyType={policyType}  />,
    <AddQuotePopup setCurrentPopup = {setCurrentPopup} quotes = {quotes} fn = {fn}/>, 
    <LeadsTabPopup userIdAndFullName ={userIdAndFullName} setCurrentPopup = {setCurrentPopup } getHouseholdData= {getHouseholdData} getSharedLeads={getSharedLeads}/>
    
    
  ]

  const components = [
    <Underwriting api = {api} uwActionRequest = {uwActionRequest} fetchUwActions = {fetchUwActions} currentUserData={currentUserData} userIdAndFullName={userIdAndFullName} />,
    <Policies driverData= {driverData} userIdAndFullName = { userIdAndFullName} api = {api} togglePopUpById = {togglePopUpById} fetchData = {fetchData} setPolicies = {setPolicies} policies={policies} currentUserData={currentUserData} setCurrentPopup={setCurrentPopup} />,
    <Documents  api = {api}  policies = {policies} togglePopUpById = {togglePopUpById} documentData = {documentData} setDocumentData ={setDocumentData} fetchDocs ={fetchDocs} currentUserData={currentUserData} setCurrentPopup={setCurrentPopup}  />,
    <Notes api={api} notes = {notes} fetchNotes={fetchNotes} currentUserData={currentUserData} userIdAndFullName = {userIdAndFullName} copyToClipboard ={copyToClipboard} /> , 
    <Quotes getHouseholdData={getHouseholdData} api ={api} userIdAndFullName = {userIdAndFullName} fn = {fn} setQuotes = {setQuotes} quotes = {quotes}/>, 
    <LeadsTab getSharedLeads ={getSharedLeads} sharedLeads={sharedLeads}/>
    
  ];





  return (
    
    <>
    {/*Tab button selector*/}
    <div className="dark-background-padding-5  flex-across-space-between "   >
    

      <button type = "button" className='middle-tab-buttons' onClick = {() => toggleComponent(5)}><button type = 'button' className='popup-tab-buttons align-items-center' onClick = {() => setCurrentPopup(13)}>+</button> Leads</button>
      <button type = "button" className='middle-tab-buttons' onClick = {() => toggleComponent(4)}> Quotes</button>
      <button type = "button" className='middle-tab-buttons' onClick={() => toggleComponent(0)} >{currentUserData && ( currentUserData[0]?.role === "Admin" || currentUserData[0]?.role === "Supervisor" ? <button type = "button" className='popup-tab-buttons' onClick = {() => {fetchData(); setCurrentPopup(0) }} >+</button>: null ) } UW Actions</button>
      <button type = "button" className='middle-tab-buttons' ><button type = "button" className='popup-tab-buttons' >+</button> Service Request</button>
      <button type = "button" className='middle-tab-buttons' onClick={() => toggleComponent(1)} ><button type = "button" className='popup-tab-buttons' onClick={() => setCurrentPopup(1)} >+</button> Policies</button>
      <button type = "button" className='middle-tab-buttons' onClick ={() => toggleComponent(2)}><button type = "button" className='popup-tab-buttons'onClick={() => {setCurrentPopup(7)}} >+</button> Documents</button>
      <button type = "button" className='middle-tab-buttons'onClick ={() => toggleComponent(3)} ><button type = "button" className='popup-tab-buttons'  onClick={() => setCurrentPopup(6)} >+</button> Notes</button>
      <button type = "button" className='middle-tab-buttons'><button type = "button" className='popup-tab-buttons' >+</button> History</button>
      <button type = "button" className='middle-tab-buttons'><button type = "button" className='popup-tab-buttons' >+</button> Email</button>
      <button type = "button" className='middle-tab-buttons' ><button type = "button" className='popup-tab-buttons' >+</button> Text SMS</button>
    </div>

  
      <div className=" dark-background-padding-5 margin-top-10px height-20vh ">
        {components[activeComponentIndex]}
        {popups[currentPopup]}
      </div>
      


   </>
    
  );
}

export default TabSection;