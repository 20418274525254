import React, { useRef, useState } from "react";
import Cookies from "js-cookie";
import { insurance_companies } from "../jsonData/insurance_companies";
const { updatePolicy } = require("../fetches/fetch");
function Edit_Policy_Popup({
  currentUserData,
  userIdAndFullName,
  setToggleEdit,
  policyData,
  setPolicyData,
  fetchData,
  driverData,
}) {
  const length_of_prem = useRef(null);
  const total_prem = useRef(null);
  const [date, setDate] = useState(policyData.effective_date.split("T")[0]);
  const [expiration_date, setExpiration_Date] = useState(
    policyData.expiration_date ? policyData.expiration_date.split("T")[0] : null
  );
  const [dateBound, setDateBound] = useState(
    policyData.date_created ? policyData.date_created.split("T")[0] : null
  );

 
  const handleSubmitBtn = async () => {
    const policy_obj = createPolicyObj();

    const response = await updatePolicy(policy_obj);
    if (response.status === 500) return alert("Error updating policy");
    await fetchData();
    return setToggleEdit(null);
  };

  const createPolicyObj = () => {
    const inputElements = document.querySelectorAll(".regularTextInput");
    let obj = {
      monthly_prem: total_prem.current.value / length_of_prem.current.value,
    };
    inputElements.forEach((input) => {
      obj[input.name] = input.value;
    });
    return obj;
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleDateBoundChange = (e) => {
    setDateBound(e.target.value);
  };

  const handleExpirationDateChange = (e) => {
    setExpiration_Date(e.target.value);
  };
  return (
    <div className="background-color-full-dark border-radius-10px popup">
      <div className="black-background  text-align-right border-radius-top-10px ">
        <button
          type="button"
          className="popup-exit-button"
          onClick={() => setToggleEdit(null)}
        >
          ❌
        </button>
      </div>
      <div className="flex-container-el padding-20px">
        <h3 className="text-align-left margin-bottom-10">
          {" "}
          Edit {policyData.policy_type.charAt(0).toUpperCase()}
          {policyData.policy_type.slice(1)} Policy
        </h3>
        <div className="height-half flex-column-no-ho-centered  padding-20px">
          <label className="background-color-full-dark text-align-left margin-top-5px ">
            Policy ID*
          </label>
          <input
            type="text"
            name="policy_id"
            className="background-color-full-dark regularTextInput margin-top-5px  max-width "
            autocomplete="off"
            value={policyData.policy_id}
          />
          <label className="background-color-full-dark text-align-left margin-top-5px ">
            Policy Number*
          </label>
          <input
            type="text"
            name="policy_id_two"
            className="background-color-full-dark regularTextInput margin-top-5px  max-width "
            autocomplete="off"
            value={policyData.policy_id_two}
          />
          <label className="background-color-full-dark text-align-left margin-top-5px ">
            {" "}
            Insurance Companies
          </label>
          <select
            className="background-color-full-dark regularTextInput margin-top-5px max-width"
            name="company"
            defaultValue={policyData.company}
            autocomplete="off"
          >
            {insurance_companies &&
              insurance_companies.map((company) => <option>{company}</option>)}
          </select>
          <label className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px ">
            Effective Date*
          </label>
          <input
            type="date"
            className="background-color-full-dark regularTextInput margin-top-5px max-width "
            name="effective_date"
            onChange={handleDateChange}
            value={date}
            autocomplete="off"
          />
          <label className="background-color-full-dark text-align-left margin-top-5px margin-top-5px ">
            Policy Status*
          </label>
          <select
            className="background-color-full-dark regularTextInput margin-top-5px  max-width "
            name="status"
            defaultValue={policyData.status}
            autocomplete="off"
          >
            <option value="Active">Active</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Pending Cancel">Pending Cancel</option>
            <option value="Terminated">Terminated</option>
          </select>
          <label className="background-color-full-dark text-align-left margin-top-5px margin-top-5px">
            Policy Address*
          </label>
          <input
            type="text"
            className="background-color-full-dark regularTextInput margin-top-5px max-width"
            name="address_line_2"
            id="address_line_2"
            defaultValue={policyData.address_line_2}
            autocomplete="off"
          />
          <label
            for="lastname"
            className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
          >
            Total Prem*
          </label>
          <input
            type="number"
            className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
            name="total_prem"
            placeholder="$0.00"
            ref={total_prem}
            defaultValue={policyData.total_prem}
            autocomplete="off"
          />
          <label
            for="lastname"
            className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
          >
            Prem Length*
          </label>
          <select
            className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
            ref={length_of_prem}
            placeholder="$0.00"
            autocomplete="off"
          >
            <option value="6">6 Months</option>
            <option value="12">12 Months</option>
          </select>
          <label
            for="lastname"
            className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
          >
            Date Bound
          </label>
          <input
            type="date"
            className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
            value={dateBound}
            onChange={handleDateBoundChange}
            name="date_created"
            autocomplete="off"
          />
          <label className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx ">
            Primary Driver*
          </label>
          <select
            name="primary_driver"
            className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
          >
            {driverData &&
              driverData.map((driver) => (
                <option value={driver.drivers_id}>{driver.full_name}</option>
              ))}
          </select>
          <label className="background-color-full-dark text-align-left margin-top-5px  margin-top-5px ">
            Expiration Date*
          </label>
          <input
            type="date"
            className="background-color-full-dark regularTextInput margin-top-5px max-width "
            name="expiration_date"
            onChange={handleExpirationDateChange}
            value={expiration_date}
            autocomplete="off"
          />
           <label
           className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
          >
            Sales Type*
          </label>
          <select
             className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
             name = "sales_type"
            defaultValue = {policyData.sales_type}
          >
            <option value= 'New Business'>New Business</option>
            <option value = 'Rewrite'>Rewrite</option>
            <option value = 'Cancel Rewrite'>Cancel Rewrite</option>
            <option value = 'Cross Sale'>Cross Sale</option>
            <option value = 'AOR Change'>AOR Change</option>
          </select>
          <label>
              Number Of Drivers
          </label>
          <input type = 'text'
            name = "number_of_drivers" 
            defaultValue={policyData.number_of_drivers}
            className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
          > 
          </input>
          <label
            for="lastname"
            className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
          >

            Commissionable Premium
          </label>
          <input
            type="number"
            name="comm_prem"
            className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
            onChange={handleDateBoundChange}
            defaultValue={policyData.commissionable_premium}
            autocomplete="off"
          />

          {currentUserData && currentUserData[0].role !== "Agent" ? (
            <>
              <label
                for="lastname"
                className="background-color-full-dark text-align-left margin-top-5pxpx  margin-top-5pxpx "
              >
                Assigned To
              </label>
              <select
                name="user_id"
                className="background-color-full-dark regularTextInput margin-top-5px request-type input max-width"
                onChange={handleDateBoundChange}
                defaultValue={userIdAndFullName[policyData.user_created_id]}
                autocomplete="off"
              >
                <option selected value={policyData.user_created_id}>
                  {userIdAndFullName[policyData.user_created_id]}
                </option>
                {Object.keys(userIdAndFullName).map((key) => {
                  return (
                    key !== policyData.user_created_id && (
                      <option key={key} value={key}>
                        {userIdAndFullName[key]}
                      </option>
                    )
                  );
                })}
              </select>
            </>
          ) : null}
        </div>
        <button
          type="button"
          class="button-default green-submit-btn margin-top-10px max-width"
          onClick={() => handleSubmitBtn()}
        >
          Edit Policy
        </button>
      </div>
    </div>
  );
}

export default Edit_Policy_Popup;
