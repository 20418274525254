import React, { useState, useEffect, useRef } from "react";
import DatePicker from "./DatePicker";
import PayrollTable from "../src/PayrollTable.js";
import PayrollCommissionAndBonusTable from "./PayrollCommissionAndBonusTable.js";
import PayrollCommissionAndBonusTableTotalPay from "./PayrollCommissionAndBonusTableTotalPay.js";
const {
  get_payroll,
  getUserCommissions,
  getBonusData,
} = require("./fetches/fetch");

function Payroll({ userIdAndFullName, allUserData }) {
  const today = new Date();
  const previousMonthDate = new Date();
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);

  const [startDate, setStartDate] = useState(
    new Date(previousMonthDate.getFullYear(), previousMonthDate.getMonth(), 25)
  );
  const [endDate, setEndDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 25)
  );
  const [allPayrollData, setAllPayrollData] = useState();
  const [totalPayout, setTotalPayout] = useState();
  const [firstPayPeriodData, setFirstPayPeriodData] = useState();
  const [lastPayPeriodData, setLastPayPeriodData] = useState();
  const [bonusData, setBonusData] = useState();
  const [commissionsData, setCommissionsData] = useState();
  const [bonusDataAfterCalculation, setBonusDataAfterCalculation] = useState();
  const [fullMonthPayrollData, setFullMonthPayrollData] = useState();
  const [lastPayPeriodCommissions, setLastPayPeriodCommissions] = useState();
  const [firstPayPeriodCommissions, setFirstPayPeriodCommissions] = useState();
 const [total_amount_header, set_total_amount_header] = useState(0);
  const toYear = useRef();
  const fromYear = useRef();
  const fromDateSelectEl = useRef();
  const toDateSelectEl = useRef();

  const setUserCommissionsData = async (startDate, endDate) => {
    const response = await getUserCommissions(startDate, endDate);
    const data = await response.json();
    setCommissionsData(data);
  };

  useEffect(() => { 
    calculateHeaderFunction();
  }, [allPayrollData])
  useEffect(() => {
    setUserCommissionsData(
      startDate.toISOString().split("T")[0],
      endDate.toISOString().split("T")[0]
    );
    fetchBonusData(startDate, endDate);
  }, [startDate, endDate]);

  function calculateBonusAmount(J5) {
    /*J5 IS THE TOTAL PREM */
    if (J5 >= 10000 && J5 < 14999) {
      return 200;
    } else if (J5 >= 15000 && J5 < 19999) {
      return 250;
    } else if (J5 >= 20000 && J5 < 29999) {
      return 500;
    } else if (J5 >= 30000 && J5 < 39999) {
      return 750;
    } else if (J5 >= 40000 && J5 < 59999) {
      return 1000;
    } else if (J5 >= 60000 && J5 < 79999) {
      return 1250;
    } else if (J5 >= 80000 && J5 < 99999) {
      return 1500;
    } else if (J5 >= 100000 && J5 <= 100000) {
      return 2000;
    } else {
      return 0;
    }
  }

  const fetchBonusData = async (startDate, endDate) => {
    const response = await getBonusData(
      null,
      startDate.toISOString().split("T")[0],
      endDate.toISOString().split("T")[0]
    );
    if (response.status === 500) {
      return alert("Error fetching bonus data");
    }
    const data = await response.json();

    calculateUsersBonus(data);
  };

  function calculateBonusAfterBundlePercentageCalculation(C6, U6, items) {
    /*    
     C6 is bundle rate percentage 
     U6 will be the bonus amount 
     If number of Items are less than 20 then return 0 
    */
    if (items < 20) {
      return 0;
    }
    if (C6 >= 0.3 && C6 <= 0.39) {
      return 0.6 * U6;
    } else if (C6 >= 0.4 && C6 <= 0.49) {
      return 0.8 * U6;
    } else if (C6 >= 0.5 && C6 <= 1) {
      return U6;
    } else {
      return 0;
    }
  }

  const calculateUsersBonus = (bonusData) => {
    const calculateBundledRateAndBonusAmount = bonusData.map((user) => {
      return {
        ["user_id"]: user.user_created_id,
        ["bundledRatePercentage"]: parseFloat(
          (user.numOfMonoline / user.numOfPolicies).toFixed(2)
        ),
        ["bonus_amount"]: calculateBonusAmount(user.total_prem),
        ["items"]: user.numOfItems,
      };
    });

    const calculateTotalBonus = calculateBundledRateAndBonusAmount.map(
      (user) => {
        return {
          ["user_id"]: user.user_id,
          ["bundledRatePercentage"]: user.bundledRatePercentage,
          ["bonus_amount"]: user.bonus_amount,
          ["bonus"]: calculateBonusAfterBundlePercentageCalculation(
            user.bundledRatePercentage,
            user.bonus_amount,
            user.items
          ),
        };
      }
    );

    setBonusDataAfterCalculation(calculateTotalBonus);
  };

  useEffect(() => {
    calculateFirstPayPeriod();
    calculateLasttPayPeriod();
    calculateCommissionsPeriod();
  
    async function fn() {
      const response = await get_payroll(
        new Date(startDate).toISOString(),
        new Date(endDate).toISOString()
      );
      const data = await response.json();
      setAllPayrollData(data);

      const total = data.reduce((acc, userPayrollData) => {
        if (userPayrollData.on_payroll === true) {
          const hourlyRate =
            allUserData.find(
              (user) => userPayrollData.user_id === String(user.id)
            )?.hourly_rate || 0;
          const payForRow =
            userPayrollData.total_hours * hourlyRate +
            (userPayrollData.total_minutes / 60) * hourlyRate;
          return acc + payForRow;
        }
        return acc;
      }, 0);

      setTotalPayout(total);
    }
    fn();
  }, [startDate, endDate]);


  

  const calculateFirstPayPeriod = async () => {
    const response = await get_payroll(
      new Date(endDate.getFullYear(), endDate.getMonth(), 1)
        .toISOString()
        .slice(0, 10),
      new Date(endDate.getFullYear(), endDate.getMonth(), 15)
        .toISOString()
        .slice(0, 10)
    );
    const data = await response.json();
    setFirstPayPeriodData(data);
  };

  const calculateLasttPayPeriod = async () => {
    const timeSheetData = await get_payroll(
      new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        16
      )
        .toISOString()
        .slice(0, 10),
      new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        0
      )
        .toISOString()
        .slice(0, 10)
    );
    const commissionsData = await getUserCommissions(new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      15
    )
      .toISOString()
      .slice(0, 10),
    new Date(
      startDate.getFullYear(),
      startDate.getMonth() + 1,
      0
    )
      .toISOString()
      .slice(0, 10)); 
    const commissionsJsonData = await commissionsData.json();
    const timesheetJsonData = await timeSheetData.json();
    setLastPayPeriodData(timesheetJsonData);
    setLastPayPeriodCommissions(commissionsJsonData)

  };

  const calculateCommissionsPeriod = async () => {
    const response = await get_payroll(
      new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
      1
      )
        .toISOString()
        .slice(0, 10),
      new Date(
        startDate.getFullYear(),
        startDate.getMonth() + 1,
        0
      )
        .toISOString()
        .slice(0, 10)
    );
    const data = await response.json();
    setFullMonthPayrollData(data);
  };

const calculateHeaderFunction = () => {
  const totalAmountHeader = allPayrollData?.reduce((total, userPayrollData) => {
    if (!userPayrollData.on_payroll) return total;
  
    const userId = userPayrollData.user_id;
  
    // Get hourly rate safely
    const userData = allUserData.find((user) => String(user.id) === userId);
    const hourlyRate = userData?.hourly_rate || 0;
  
    // Calculate total earnings
    const totalEarnings = 
      userPayrollData.total_hours * hourlyRate + 
      (userPayrollData.total_minutes / 60) * hourlyRate;
  
    // Calculate net commission
    const userCommission = commissionsData?.find((user) => user.id === userId);
    const commissionAmount = userCommission?.commission_amount || 0;
    const totalMvrFee = userCommission?.total_mvr_fee || 0;
    const totalClueFee = userCommission?.total_clue_fee || 0;
    const netCommission = commissionAmount - totalMvrFee - totalClueFee;
  
    // Get bonus
    const bonus = bonusDataAfterCalculation?.find((user) => user.user_id === userId)?.bonus || 0;
  
    // Sum up all relevant amounts
    return total + totalEarnings + netCommission + bonus;
  }, 0).toFixed(2);
  console.log(totalAmountHeader)
  set_total_amount_header(totalAmountHeader)
  
}
  

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="full-height-overflow-y-90">
      <div className="flex align-items-center light-dark-background margin-bottom-10  ">
        <p className="light-dark-background padding-5px">From: 25th Of </p>
        <select
          value={new Date(startDate).getMonth()}
          className="regularTextInput"
          ref={fromDateSelectEl}
          onChange={(e) => {
            const date = new Date();
            date.setMonth(e.target.value);
            date.setDate(25);
            date.setFullYear(fromYear.current.value);
            setStartDate(date);
          }}
        >
          {months &&
            months.map((month, index) => (
              <option value={index}>{month}</option>
            ))}
        </select>
        <input
          type="number"
          className="regularTextInput margin-left-5 wdith-70px"
          defaultValue={previousMonthDate.getFullYear()}
          placeholder="yyyy"
          min="1000"
          max="9999"
          ref={fromYear}
          maxlength={4}
          onChange={(e) => {
            /*Only updates when a valid year is present in the field */
            if (e.target.value > 1000 && e.target.value < 9000) {
              const date = new Date();
              date.setMonth(fromDateSelectEl.current.value);
              date.setDate(25);
              date.setFullYear(fromYear.current.value);
              setStartDate(date);
            }
          }}
        />

        <p className="light-dark-background margin-left-5" >
          To: 25th Of
        </p>

        <select
          className="regularTextInput margin-left-5 "
          ref={toDateSelectEl}
          value={new Date(endDate).getMonth()}
          onChange={(e) => {
            const date = new Date();
            date.setMonth(e.target.value);
            date.setDate(25);
            date.setFullYear(toYear.current.value);
            setEndDate(date);
          }}
        >
          {months &&
            months.map((month, index) => (
              <option value={index}>{month}</option>
            ))}
        </select>
        <input
          type="number"
          className="regularTextInput margin-left-5 wdith-70px"
          defaultValue={today.getFullYear()}
          min="1000"
          max="9999"
          ref={toYear}
          maxlength={4}
          onChange={(e) => {
            /*Only updates when a valid year is present in the field */
            if (e.target.value > 1000 && e.target.value < 9000) {
              const date = new Date();
              date.setMonth(toDateSelectEl.current.value);
              date.setDate(25);
              date.setFullYear(toYear.current.value);
              setEndDate(date);
            }
          }}
        />
      </div>
      <div className="flex-across-space-between flex-column-no-ho-centered max-height">
        <div>
          <div className="bottom-margin-25">
            <h3>
              Commission and Bonus
              <i>
                {" "}
                25th of{" "}
                {startDate.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </i>
            </h3>
            <PayrollCommissionAndBonusTable
              payrollData={fullMonthPayrollData}
              userIdAndFullName={userIdAndFullName}
              allUserData={allUserData}
              commissionsData={commissionsData}
            />
          </div>
          <div className=" bottom-margin-25 ">
            <h3>
              Timesheet & Salary
              <i>
                {" "}
                15th - 31st of{" "}
                {startDate.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </i>
            </h3>
            <PayrollTable
              commissionsData ={lastPayPeriodCommissions}
              payrollData={lastPayPeriodData}
              userIdAndFullName={userIdAndFullName}
              allUserData={allUserData}
            />
          </div>
          <div className=" bottom-margin-25">
            <h3>
              Timesheet & Salary
              <i>
                {" "}
                1st - 15th of{" "}
                {endDate.toLocaleDateString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </i>
            </h3>
            <PayrollTable
              payrollData={firstPayPeriodData}
              userIdAndFullName={userIdAndFullName}
              allUserData={allUserData}
            />
          </div>
        </div>
        <div>
          <div className=" margin-bottom-10 ">
            <div class>
            <h3>Totals</h3>
             <h3>${total_amount_header && total_amount_header}</h3>
            </div>
         

            <i>
              <b>Start: </b>
              {"25th " +
                new Date(startDate).toLocaleDateString("default", {
                  month: "long",
                }) +
                ", " +
                new Date(startDate).getFullYear()}
              {"    "}
              <b>End: </b>
              {"To: 25th " +
                new Date(endDate).toLocaleDateString("default", {
                  month: "long",
                }) +
                ", " +
                new Date(endDate).getFullYear()}
            </i>
          </div>
          <PayrollCommissionAndBonusTableTotalPay
            payrollData={allPayrollData}
            userIdAndFullName={userIdAndFullName}
            allUserData={allUserData}
            commissionsData={commissionsData}
            bonusData={bonusData}
            startDate={startDate}
            endDate={endDate}
            bonusDataAfterCalculation={bonusDataAfterCalculation}
          />
        </div>
      </div>
    </div>
  );
}

export default Payroll;
