import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import usePreventDoubleClick from "./usePreventDoubleClick";
const { completeUnderWritingAction} = require('../src/fetches/fetch')
function Underwriting({
  api,
  fetchUwActions,
  uwActionRequest,
  currentUserData,
  userIdAndFullName,
  setSwitchNumber,
}) {
  const userId = Cookies.get("userid");
  const jwtToken = Cookies.get("jwt");
  const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();
  const is_completed = "is_completed"; 
  useEffect(() => {
    fetchUwActions();
  }, []);

  const deleteUwAction = async (uwId) => {
    const response = await fetch(
      `${api}/api/uw_action_delete?uwactionid=${uwId}`,
      {
        method: "DELETE",
      }
    );
    fetchUwActions();
  };
  const handleCompletingUWAction = async(status, uw_id) => { 
   const response =  await completeUnderWritingAction(status, uw_id);
   if(response.status === 200){ 
    return await fetchUwActions() 
  }
  return alert('Error changing underwriting action status')
    
  }

  return (
    <>
      <div class="table-wrapper overflow-wrapper">
        <table>
          <thead>
            <tr>
              <th>Assignment Information</th>
              <th>Request Type</th>
              <th>Uploaded Docs</th>
              <th>Status</th>
              <th>Additional Comments</th>
            </tr>
          </thead>
          <tbody>
            {currentUserData &&
              uwActionRequest &&
              uwActionRequest.map((action, index) => (
                <tr key={index}>
                  <td>
                    <b className="color-red">Assigned To:</b>{" "}
                    {userIdAndFullName[action.assigned_to]}
                    <br />
                    <b className="color-red">Supervisor:</b>{" "}
                    {userIdAndFullName[action.created_by]}
                    <br />
                    <b className="color-orange">Created on:</b>{" "}
                    {new Date(action.created_on).toLocaleDateString("en-US")}
                    <br />
                    <b className="color-red">Due on:</b>{" "}
                    {new Date(action.due_date).toLocaleDateString("en-US")}
                    <br />
                    {(currentUserData && currentUserData[0].role === "Admin") ||
                    currentUserData[0].role === "Supervisor" ? (
                      <div className="flex-column-no-ho-centered">
                        <b
                          className="hover-cursor-pointer"
                          onClick={() => deleteUwAction(action.uw_action_id)}
                        >
                          🗑️Delete{" "}
                        </b>
                        <span>
                          <b className="hover-cursor-pointer" >Completed:</b>
                          <select onChange = {(e) => handleCompletingUWAction(e.target.value, action.uw_action_id) } value={action[is_completed + " "]}>
                            <option value = {true}>Yes</option>
                            <option value = {false}>No</option>
                          </select>
                        </span>
                      </div>
                    ) : null}
                  </td>
                  <td>
                    {action.additional_docs === "true" ? (
                      <>
                        <b className="color-red">Additional Documents</b> <br />
                      </>
                    ) : null}
                    {action.endorsements === "true" ? (
                      <>
                        <b className="color-red">Endorsements</b> <br />
                      </>
                    ) : null}
                    {action.proof_of_home_ownership === "true" ? (
                      <>
                        <b className="color-red">Proof of Home Ownership</b>{" "}
                        <br />
                      </>
                    ) : null}
                    {action.drivers_license === "true" ? (
                      <>
                        <b className="color-red">Drivers License</b> <br />
                      </>
                    ) : null}
                    {action.fl_dl_check === "true" ? (
                      <>
                        <b className="color-red">FL DL Check</b> <br />
                      </>
                    ) : null}
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    {action.notes && <span>Notes: </span>}
                    {action.notes}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Underwriting;
