import React, { useEffect, useState } from "react";
const { getUserCommissions, getBonusData } = require("./fetches/fetch");
function PayrollCommissionAndBonusTable({ payrollData, userIdAndFullName }) {
  const [bonusDataAfterCalculation, setBonusDataAfterCalculation] = useState();
  const [commissionsData, setCommissionsData] = useState();
  const periodStart = new Date();
  periodStart.setMonth(periodStart.getMonth() - 2);
  periodStart.setDate(1);
  const periodEnd = new Date();
  periodEnd.setDate(0);

  useEffect(() => {
    fetchBonusData(periodStart, periodEnd);
    setUserCommissionsData(periodStart, periodEnd);
  }, []);

  const setUserCommissionsData = async (startDate, endDate) => {
    const response = await getUserCommissions(startDate, endDate);
    const data = await response.json();
    setCommissionsData(data);
  };

  function calculateBonusAmount(J5) {
    /*J5 IS THE TOTAL PREM */
    if (J5 >= 10000 && J5 < 14999) {
      return 200;
    } else if (J5 >= 15000 && J5 < 19999) {
      return 250;
    } else if (J5 >= 20000 && J5 < 29999) {
      return 500;
    } else if (J5 >= 30000 && J5 < 39999) {
      return 750;
    } else if (J5 >= 40000 && J5 < 59999) {
      return 1000;
    } else if (J5 >= 60000 && J5 < 79999) {
      return 1250;
    } else if (J5 >= 80000 && J5 < 99999) {
      return 1500;
    } else if (J5 >= 100000 && J5 <= 100000) {
      return 2000;
    } else {
      return 0;
    }
  }

  const fetchBonusData = async (startDate, endDate) => {
    const response = await getBonusData(
      null,
      startDate.toISOString().split("T")[0],
      endDate.toISOString().split("T")[0]
    );
    if (response.status === 500) {
      return alert("Error fetching bonus data");
    }
    const data = await response.json();

    calculateUsersBonus(data);
  };

  function calculateBonusAfterBundlePercentageCalculation(C6, U6, items) {
    /*    
     C6 is bundle rate percentage 
     U6 will be the bonus amount 
     If number of Items are less than 20 then return 0 
    */
    if (items < 20) {
      return 0;
    }
    if (C6 >= 0.3 && C6 <= 0.39) {
      return 0.6 * U6;
    } else if (C6 >= 0.4 && C6 <= 0.49) {
      return 0.8 * U6;
    } else if (C6 >= 0.5 && C6 <= 1) {
      return U6;
    } else {
      return 0;
    }
  }

  const calculateUsersBonus = (bonusData) => {
    const calculateBundledRateAndBonusAmount = bonusData.map((user) => {
      return {
        ["user_id"]: user.user_created_id,
        ["bundledRatePercentage"]: parseFloat(
          (user.numOfMonoline / user.numOfPolicies).toFixed(2)
        ),
        ["bonus_amount"]: calculateBonusAmount(user.total_prem),
        ["items"]: user.numOfItems,
      };
    });

    const calculateTotalBonus = calculateBundledRateAndBonusAmount.map(
      (user) => {
        return {
          ["user_id"]: user.user_id,
          ["bundledRatePercentage"]: user.bundledRatePercentage,
          ["bonus_amount"]: user.bonus_amount,
          ["bonus"]: calculateBonusAfterBundlePercentageCalculation(
            user.bundledRatePercentage,
            user.bonus_amount,
            user.items
          ),
        };
      }
    );

    setBonusDataAfterCalculation(calculateTotalBonus);
  };
 

  return (
    <table>
      <thead>
        <th>Employee</th>
        <th>Bonus </th>
        <th>Commissions</th>
        <th>Total Pay</th>
      </thead>
      <tbody>
        {payrollData &&
          payrollData.map((userPayrollData) => {
            return userPayrollData.on_payroll === true ? (
              
              <tr className="border-bottom">
                <td>{userIdAndFullName[userPayrollData.user_id]}</td>

                <td>
                  $
                  {bonusDataAfterCalculation &&
                    bonusDataAfterCalculation.find(
                      (user) => user.user_id === userPayrollData.user_id
                    )?.bonus ? bonusDataAfterCalculation.find(
                      (user) => user.user_id === userPayrollData.user_id
                    )?.bonus : 0 }
                </td>
                <td>
                  $
                  {(() => {
                    const user = commissionsData?.find(
                      (user) => user.id === userPayrollData.user_id
                    );
                    const commissionAmount = user?.commission_amount;

                    return commissionAmount && !isNaN(commissionAmount)
                      ? ((commissionAmount  - user.total_mvr_fee ) - user.total_clue_fee).toFixed(2)
                      : 0;
                  })()}
                </td>
                <td>
                  $
                  {(() => {
                    const commissionData = commissionsData?.find(
                      (user) => user.id === userPayrollData.user_id
                    );
                    const bonusData = bonusDataAfterCalculation.find(
                      (user) => user.user_id === userPayrollData.user_id
                    )
                    const commissionAmount = commissionData?.commission_amount;
                    const bonusAmount = bonusData?.bonus;
                    

                    return commissionAmount && !isNaN(commissionAmount) 
                      ?  (bonusAmount + ((commissionAmount  - commissionData.total_mvr_fee ) - commissionData.total_clue_fee)).toFixed(2)
                      : 0;

                  })()}
                </td>
              </tr>
            ) : null;
          })}
      </tbody>
    </table>
  );
}

export default PayrollCommissionAndBonusTable;
