import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import ChatBox from "./chatBox";
import "./navbar.css";
import io from "socket.io-client";
import Add_story from "./leads_page_popups/Add_story";
import { useSelector, useDispatch } from "react-redux";
import { set_true, set_false } from "./redux/firstSlice";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { blueGrey, grey, pink } from "@mui/material/colors";
import usePreventDoubleClick from "./usePreventDoubleClick";
import Chat_Container from "./chat_components/Chat_Container";

let { api } = require("./apiConfig");
const { getAnnouncement, postTimePunch } = require("./fetches/fetch");

function Navbar({
  setSwitchNumber,
  allUserData,
  currentUserData,
  userIdAndFullName,
  setCurrentPopup,
  currentPopup,
  socket,
  setSocket,
  toggleNotificationPanel,
  userNotifications,
  notificationPanel,
  breakStatus,
  setBreakStatus
}) {
  const [navBarPopUpVisible, setNavBarPopUpVisible] = useState(false);
  const [showChatBox, setShowChatBox] = useState(false);
  const [chatBoxUserData, setChatBoxUserData] = useState();
  const [userPreviousMessages, setUserPreviousMessages] = useState();
  const [receiverPreviousMessages, setReceiverPreviousMessages] = useState();
  const [chatMessages, setChatMessages] = useState();

  const [notificationCount, setNotificationCount] = useState({});
  const [chatDisplayName, setChatDisplayName] = useState();
  const [fromUserIDS, setFromUserIDS] = useState([]);
  const [fromUserID, setFromUserID] = useState();
  const [notificationData, setNotificationData] = useState();
  const [userNotificationData, setUserNotificationData] = useState();
  const [currentSelectedUserId, setCurrentSelectedUserId] = useState();
  const [announcement, setAnnouncement] = useState();
  const { isButtonDisabled, preventDoubleClick } = usePreventDoubleClick();

  const dispatch = useDispatch();

  /* Bad code please refactor */
  const popups = [
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    <Add_story setCurrentPopup={setCurrentPopup} />,
  ];

  const navigate = useNavigate();

  let userid = Cookies.get("userid");
  const handleLogout = async () => {
    const response = await postTimePunch(
      Cookies.get("userid"),
      new Date(),
      "logout"
    );
    if (response.status === 200) {
      // Delete the JWT cookie
      Cookies.remove("jwt");
      Cookies.remove("userid");
      navigate("/");
    }
  };

  const handleTakeBreak = async () => {
    let response;
    if (breakStatus === "break_on") {
      response = await postTimePunch(
        Cookies.get("userid"),
        new Date(),
        "break_off"
      );
      setBreakStatus('break_off')
    } else {
      response = await postTimePunch(
        Cookies.get("userid"),
        new Date(),
        "break_on"
      );
      setBreakStatus('break_on')
    }
    if (response.status === 500) {
      return alert("error taking break!");
    }
  };

  const toggleNavBarPopUp = () => {
    setNavBarPopUpVisible(!navBarPopUpVisible);
  };

  const toggleTeamsPopup = () => {
    document.getElementById("teams-popup").hidden =
      !document.getElementById("teams-popup").hidden;
  };

  const fetchAllUserNotifications = async (to_user_id, from_user_id) => {
    const response = await fetch(
      `${api}/api/notifications?toUserId='${to_user_id}'&fromUserId='${from_user_id}'`
    );
    const data = await response.json();
    setNotificationData(data);
  };

  const deleteNotifications = async (to_user_id, from_user_id) => {
    const response = await fetch(
      `${api}/api/notifications?toUserId='${to_user_id}'&fromUserId='${from_user_id}'`,
      {
        method: "DELETE",
      }
    );

    if (response.ok) {
      document.title = "Agentz";
      fetchAllUserNotifications(to_user_id, from_user_id);
      console.log("Message seen");
    }
  };

  const getNotiifcations = async () => {
    const response = await fetch(
      `${api}/api/notification/user?toUserId=${userid}`,
      {
        headers: {
          Authorization: Cookies.get("jwt"),
        },
      }
    );
    const data = await response.json();
    if (data.length !== 0) {
      document.getElementById("teams-popup").hidden = false;
      document.title = "Agetnz - New Message!";
    }
    if (response.ok) {
      setUserNotificationData(data);
    } else {
      Cookies.remove("jwt");
      window.location.href = "/";
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getNotiifcations();
    }, 3000);
    return () => clearInterval(intervalId);
  }, [api]);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        socket.emit("userConnected", userid);
      });
      socket.on("receiveMessages", (messages, fromUserId, toUserId) => {
        fetchAllUserNotifications(toUserId, fromUserId);
        if (toUserId == Cookies.get("userid")) {
          setFromUserIDS((previousIds) => [...previousIds, fromUserId]);
        }
      });
      document.getElementById("teams-popup").hidden = false;
    }
  }, [socket, userid]);

  useEffect(() => {
    const fetchAnnouncement = async () => {
      try {
        const response = await getAnnouncement();
        const data = await response.json();
        setAnnouncement(data);
      } catch (error) {
        console.error("Error fetching announcement:", error);
      }
    };

    fetchAnnouncement();

    const intervalId = setInterval(fetchAnnouncement, 300000);
    return () => clearInterval(intervalId);
  }, [api]);

  const getPrivateMessages = async (sender, receiver) => {
    const userDataResponse = await fetch(
      `${api}/api/private-chats?sender=${sender}&receiver=${receiver}`
    );
    const userData = await userDataResponse.json();

    setUserPreviousMessages(userData);

    const receiverDataResponse = await fetch(
      `${api}/api/private-chats?sender=${receiver}&receiver=${sender}`
    );
    const receiverData = await receiverDataResponse.json();

    setReceiverPreviousMessages(receiverData);

    // Assuming each message object has a unique ID
    let chatHistory = [...userData, ...receiverData];

    // Convert the object into an array of values
    let chatEntries = chatHistory;

    // Sort the array by date_created (assuming date_created is a property in each entry)
    chatEntries.sort(
      (a, b) => new Date(a.date_created) - new Date(b.date_created)
    );

    setChatMessages(chatEntries);
  };

  const handleUserClick = (userData) => {
    setCurrentSelectedUserId(userData.id);

    setShowChatBox(true);
    setChatBoxUserData(userData.id);
    getPrivateMessages(userid, userData.id);
    setChatDisplayName(userData.full_name);
    setFromUserID(userData.id);
    deleteNotifications(Cookies.get("userid"), userData.id);
    console.log(currentSelectedUserId); // Directly use userData.id instead of fromUserID
  };

  return (
    <>
      {showChatBox ? (
        <ChatBox
          //currentSelectedUserId={currentSelectedUserId}
          setShowChatBox={setShowChatBox}
          chatBoxUserData={chatBoxUserData}
          socket={socket}
          chatMessages={chatMessages}
          //getPrivateMessages={getPrivateMessages}
          //notificationCount={notificationCount}
          setNotificationCount={setNotificationCount}
          chatDisplayName={chatDisplayName}
          showChatBox={showChatBox}
          fromUserID={fromUserID}
          setFromUserID={setFromUserID}
          userIdAndFullName={userIdAndFullName}
        />
      ) : null}
      <nav className="navbar flex-across-space-between ">
        <button
          className="max-height"
          type="button"
          id="nav-popup-menu-btn"
          onClick={() => dispatch(set_true())}
        >
          ☰
        </button>
        <div className=" overflow-x  light-dark-background flex-center-hor max-width ">
          <label className="light-dark-background ticker max-width ">
            {userNotificationData && userNotificationData.length > 0
              ? "New message"
              : announcement && announcement.announcement_desc}
          </label>
        </div>
        <div className="light-dark-background flex-across width-25-percent space-between align-items-center">
          <NotificationsIcon
            fontSize="small"
            htmlColor="black"
            className={`cursor-pointer light-dark-background ${
              userNotifications &&
              userNotifications.length &&
              notificationPanel === false > 0
                ? "shake"
                : null
            } `}
            onClick={() => {
              toggleNotificationPanel((prevState) => {
                return !prevState;
              });
            }}
          />
          <h4
            className={ breakStatus === "break_off" ? "light-dark-background cursor-pointer " : "light-dark-background cursor-pointer color-red" }
            onClick={() => handleTakeBreak()}
          >
            {" "}
            {breakStatus === "break_off" ? "Take Break" : "Clock In"}
          </h4>
          <h4
            className="light-dark-background cursor-pointer"
            onClick={() => toggleTeamsPopup()}
          >
            Teams
          </h4>

          <h4
            className=" light-dark-background cursor-pointer"
            onClick={() => {
              preventDoubleClick(handleLogout);
            }}
            disabled={isButtonDisabled}
          >
            Logout
          </h4>
        </div>
      </nav>

      <div
        className="position-absolute margin-top-50px position-right  z-index "
        id="teams-popup"
        data-testid="teams-popup-display"
      >
        <ul className="light-dark-background ">
          {allUserData
            ? allUserData.map((userData, index) => (
                <div className="light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer margin-top-5px display-flex">
                  <li
                    className="light-dark-background padding-left-2px inline-el "
                    data-testid="chat-btn"
                    onClick={() => handleUserClick(userData)}
                  >
                    {userData.id === userid ? null : userData.full_name}
                    {userNotificationData &&
                      userNotificationData.some(
                        (notification) =>
                          notification.from_user_id === userData.id
                      ) && (
                        <span className="notification-count color-red light-dark-background">
                          New
                        </span>
                      )}
                  </li>
                </div>
              ))
            : null}
        </ul>
      </div>

      {currentPopup && popups[currentPopup]}
    </>
  );
}

export default Navbar;
