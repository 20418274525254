import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { changePageState } from "../redux/pageNavigator";
import { isFirstDayOfMonth } from "date-fns";
const { getPoliciesByUserId } = require("../fetches/fetch");

function PoliciesRelatedToUserPopup({
  setViewUserID,
  viewUserID,
  setTogglePoliciesPopup,
  currentUserData,
  userIdAndFullName,
  setCurrentHouseholdId,
}) {
  const dispatch = useDispatch();
  const [policies, setPolicies] = useState();

  const [computedPremTotal, setComputedPremTotal] = useState();
  const [computedItemsTotal, setComputedItemsTotal] = useState();
  const [commisionablePremTotal, setCommissionablePremTotal] = useState();
  const [numberOfPoliciesTotal, setNumberOfPoliciesTotal] = useState();
  const [sales_type, setSalesType] = useState("");
  const [isFirstDayOfMonth, setFirstDayOfMonth] = useState(() => {
    const now = new Date();
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    return firstDayOfMonth
  });
  const [endDayOfMonth, setEndDayOfMonth] = useState(( )=> { 
    const now = new Date();
const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
return lastDayOfMonth;

  });

  const fromDate = useRef();
  const toDate = useRef();

  useEffect(() => {
    asyncFunc();
  }, [viewUserID, sales_type]);

  const asyncFunc = async () => {
    if (fromDate.current.value > toDate.current.value) {
      alert("From date cannot be newer than To date!");
      fromDate.current.value = null;
      toDate.current.value = null;
      asyncFunc();
      return;
    }

    const response = await getPoliciesByUserId(
      viewUserID,
      fromDate.current.value,
      toDate.current.value,
      sales_type
    );
    const data = await response.json();
    setPolicies(data);
    computeTotals(data);
  };

  const handleDateChange = () => {
    asyncFunc();
  };

  const computeTotals = (policies) => {
    if (policies) {
      setComputedPremTotal(
        policies.reduce((total, policy) => total + (policy.total_prem || 0), 0)
      );
      setComputedItemsTotal(
        policies.reduce((total, policy) => total + (policy.items || 0), 0)
      );
      setCommissionablePremTotal(
        policies.reduce(
          (total, policy) => total + (policy.commissionable_premium || 0),
          0
        )
      );

      setNumberOfPoliciesTotal(policies.length);
    }
  };

  const handlePolicyClick = (household_id, page) => {
    Cookies.set("currentHouseholdId", household_id);
    setCurrentHouseholdId(household_id);
    dispatch(changePageState("lead"));
  };
  const clearFilter = () => {
    fromDate.current.value = null;
    toDate.current.value = null;
    asyncFunc();
  };
  return (
    <>
      <div className="background-color-full-dark border-radius-10px popup z-index ">
        <div className="black-background flex-across-space-between text-align-right border-radius-top-10px align-items-center ">
        <h4 className="black-background">Performance</h4>
          <button
            type="button"
            className="popup-exit-button"
            onClick={() => setTogglePoliciesPopup(false)}
          >
            ❌
          </button>
        </div>
        <div className=" padding-20px light-dark-background  ">
 
          <div className="flex-across-space-between light-dark-background margin-bottom-10 regularTextInput padding-20px">
            <p className="light-dark-background ">
              <h3 className="light-dark-background">
                {computedPremTotal
                  ? "$" + Number(computedPremTotal).toFixed(2)
                  : "0"}
              </h3>
              <i className="light-dark-background">Prem Total</i>
            </p>
            <p className="light-dark-background">
              <h3 className="light-dark-background">{computedItemsTotal}</h3>{" "}
              <i className="light-dark-background"> Items Total </i>
            </p>
            <p className="light-dark-background">
              <h3 className="light-dark-background">{numberOfPoliciesTotal}</h3>
              <i className="light-dark-background"> Number Of Policies</i>
            </p>
            <p className="light-dark-background">
              <h3 className="light-dark-background">
                {commisionablePremTotal
                  ? " $" + Number(commisionablePremTotal * 0.04).toFixed(2)
                  : null}
                {"0"}
              </h3>
              <i className="light-dark-background">Base Commission Total</i>
            </p>
            <div className="light-dark-background">
              <input
                className="light-dark-background"
                type="date"
                name="from "
                defaultValue={isFirstDayOfMonth.toISOString().split("T")[0]}
                ref={fromDate}
              />
              <br />
              <span className="light-dark-background">
                <i className="light-dark-background">Start Date</i>
              </span>
            </div>
            <div className="light-dark-background">
              <input
                className="light-dark-background"
                type="date"
                name="to"
                defaultValue={endDayOfMonth.toISOString().split("T")[0]}
                ref={toDate}
              />
              <br />
              <span className="light-dark-background ">
                <i className="light-dark-background">End Date</i>
              </span>
            </div>

            {currentUserData && currentUserData[0].role === "Admin" && (
              <div className="light-dark-background">
                <select
                  className="light-dark-background regularTextInput padding-left-none"
                  onChange={(e) => {
                    setViewUserID(e.target.value);
                  }}
                >
                  <option value={""}>All Users</option>
                  {Object.keys(userIdAndFullName).map((userID) =>
                    userID !== viewUserID ? (
                      <option value={userID}>
                        {userIdAndFullName[userID]}
                      </option>
                    ) : (
                      <option selected value={viewUserID}>
                        {userIdAndFullName[viewUserID]}
                      </option>
                    )
                  )}
                </select>
              </div>
            )}
          </div>
          <div className="light-dark-background margin-bottom-10 ">
            <button
              onClick={(e) => handleDateChange(e)}
              className="green-submit-btn"
            >
              Filter
            </button>
            <button onClick={() => clearFilter()} className="green-submit-btn">
              Clear
            </button>
          </div>
          <div className="height-half">
            <table className="th-style ">
              <thead>
                <th>Primary Driver Name</th>
                <th>Policy Number</th>
                <th>Policy Type</th>
                <th>Date Bound</th>
                <th>Producer</th>
                <th>Company</th>
                <th>Effective Date</th>
                <th className="load-size ">
                  <select
                    className="bg-color-black-1 max-width"
                    onChange={(e) => setSalesType(e.target.value)}
                  >
                    <option value="">Sales Type</option>
                    <option value="New Business">New Business</option>
                    <option value="Rewrite">Rewrite</option>
                    <option value="Cross Sale">Cross Sale</option>
                    <option value="Cancel Rewrite">Cancel Rewrite</option>
                    <option value = 'AOR Change'>AOR Change</option>
                  </select>
                </th>
                <th>Status</th>
                <th>Prem Total</th>
              </thead>
              <tbody id="search-results_table_body">
                {policies
                  ? policies.map((policy) => (
                      <>
                        <tr
                          onClick={() => {
                            handlePolicyClick(policy.household_id[0], "lead");
                          }}
                        >
                          <td>{policy.firstname + " " + policy.lastname}</td>
                          <td>{policy.policy_id_two}</td>
                          <td>{policy.policy_type}</td>
                          <td>
                            {new Date(
                              policy.date_created[0]
                            ).toLocaleDateString("en-US", { timeZone: "UTC" })}
                          </td>
                          <td>{userIdAndFullName[policy.user_created_id]}</td>
                          <td>{policy.company}</td>
                          <td>
                            {new Date(policy.effective_date).toLocaleDateString(
                              "en-US",
                              { timeZone: "UTC" }
                            )}
                          </td>
                          <td>{policy.sales_type}</td>
                          <td>{policy?.status[0]}</td>
                          <td>${policy.total_prem}</td>
                        </tr>
                      </>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default PoliciesRelatedToUserPopup;
