import React, {useEffect, useState} from 'react'

function PayrollTable({payrollData, userIdAndFullName, allUserData, commissionsData}) {

  return (
    <table>
    <thead>
      <th>Employee</th>
      <th>Total Hours And Minutes</th>
      <th>Salary</th>
      <th>Hourly</th>
      <th>Total Pay</th>
    </thead>
    <tbody>
      {payrollData &&
        payrollData.map((userPayrollData) => {
          return userPayrollData.salary !== null ? (
            <tr className='border-bottom'>
              <td>{userIdAndFullName[userPayrollData.user_id]}</td>
              <td>  
              {userPayrollData.total_hours}hrs{" "}
              {userPayrollData.total_minutes}m
              </td>
              <td>${userPayrollData.salary}</td>
              <td>${allUserData && allUserData.find((user) => user.id === userPayrollData.user_id )?.hourly_rate}</td>
              <td>
              {" "}
                $
                {userPayrollData.salary === 0 ? (
                  userPayrollData.total_hours *
                    allUserData.filter(
                      (user) => userPayrollData.user_id === String(user.id)
                    )[0]?.hourly_rate +
                  (userPayrollData.total_minutes / 60) *
                    allUserData.filter(
                      (user) => userPayrollData.user_id === String(user.id)
                    )[0]?.hourly_rate
                ).toFixed(2) : userPayrollData.salary}
              
              </td>
          
            </tr>
          ) : null;
        })}
    </tbody>
  
  </table>
  )
}

export default PayrollTable