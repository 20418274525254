import React from "react";
import { useEffect, useState } from "react";
import Coverages from "./Coverages.js";
import Cookies from "js-cookie";
import SelectCoverageType from "./coverages_popups/SelectCoverageType.js";
import AddAutoCoverage from "./coverages_popups/AddAutoCoverage.js";
import AddMotorCoverage from "./coverages_popups/AddMotorCoverage.js";
import AddJewelryCoverage from "./coverages_popups/AddJewelryCoverage.js";
import AddHomeCoverage from "./coverages_popups/AddHomeCoverage.js";
import AddRentalCoverage from "./coverages_popups/AddRentalCoverage.js";
import PUP_policy_popup from "./coverages_popups/PUPCoverage.js";
import Edit_Policy_Popup from "./policy_popups/EditPolicy.js";
import Transaction from "./Transaction.js";
import ConfirmDelete from "./middle_popups/ConfirmDelete.js";
import NewTransaction from "./policy_popups/NewTransactionPopup.js";
const { getTransactions } = require("./fetches/fetch");
let api = require("./apiConfig");
api = api.api;

const jwt = Cookies.get("jwt");

function Policies({
  currentUserData,
  togglePopUpById,
  fetchData,
  policies,
  userIdAndFullName,
  driverData,
}) {
  const [fetchedCoverages, setFetchedCoverages] = useState();
  const [currentCoveragePopup, setCurrentCoveragePopup] = useState(null);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [policyData, setPolicyData] = useState(null);
  const [currentPolicyId, setCurrentPolicyId] = useState(null);
  const [transactions, setTransactions] = useState();
  const [toggleDelete, setToggleDelete] = useState();
  const [deleteThisPolicy, setDeletePolicy] = useState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchCoverages = async (policyType, policyId) => {
    /*Change this code moving forward this is because the attributes for the the other policy type coverages are not defined as yet. */
    const workingPolicyTypes = new Set([
      "automobile",
      "jewelery",
      "motorcycle",
      "home",
    ]);
    if (workingPolicyTypes.has(policyType)) {
      const response = await fetch(
        `${api}/api/policy/${policyId}/coverages?type=${policyType}`
      );
      const data = await response.json();
      setFetchedCoverages(data);
    }
  };
  const sendCoverageData = async (dataObj) => {
    const response = await fetch(
      `${api}/api/policy/${dataObj.belong_to_policy}/coverages?type=${dataObj.type}`,
      {
        method: "POST",

        headers: {
          Authorization: jwt,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataObj),
      }
    );

    return response;
  };

  const deletePolicy = async (policy_id, policy_type, household_id) => {
    const response = await fetch(
      `${api}/api/policy?policy_id=${policy_id}&policy_type=${policy_type}&household_id=${household_id}`,
      {
        method: "DELETE",
        headere: {
          Authorization: jwt,
        },
      }
    );
    if (!response.ok) {
      alert("Error deleting");
    } else {
      fetchData();
    }
  };

  const fetchTransactions = async (policy_id) => {
    const response = await getTransactions(policy_id);
    const data = await response.json();
    setTransactions(data);
    setCurrentPolicyId(policy_id);
  };

  const popups = [
    <SelectCoverageType setCurrentCoveragePopup={setCurrentCoveragePopup} />,
    <AddAutoCoverage
      setCurrentCoveragePopup={setCurrentCoveragePopup}
      policies={policies}
      sendCoverageData={sendCoverageData}
      fetchData={fetchData}
      fetchCoverages={fetchCoverages}
    />,
    <AddMotorCoverage
      setCurrentCoveragePopup={setCurrentCoveragePopup}
      sendCoverageData={sendCoverageData}
      policies={policies}
      fetchCoverages={fetchCoverages}
    />,
    <AddJewelryCoverage
      setCurrentCoveragePopup={setCurrentCoveragePopup}
      sendCoverageData={sendCoverageData}
      policies={policies}
      fetchCoverages={fetchCoverages}
    />,
    <AddHomeCoverage
      driverData={driverData}
      setCurrentCoveragePopup={setCurrentCoveragePopup}
      sendCoverageData={sendCoverageData}
      policies={policies}
      fetchCoverages={fetchCoverages}
    />,
    <AddRentalCoverage
      setCurrentCoveragePopup={setCurrentCoveragePopup}
      sendCoverageData={sendCoverageData}
      policies={policies}
      fetchCoverages={fetchCoverages}
    />,
    <PUP_policy_popup
      setCurrentCoveragePopup={setCurrentCoveragePopup}
      sendCoverageData={sendCoverageData}
      policies={policies}
      fetchCoverages={fetchCoverages}
    />,
    <Edit_Policy_Popup
      currentUserData={currentUserData}
      userIdAndFullName={userIdAndFullName}
      policyData={policyData}
      setToggleEdit={setToggleEdit}
      setPolicyData={setPolicyData}
      fetchData={fetchData}
      driverData={driverData}
    />,
  ];

  const getPrimaryDriverFullName = (primary_driver_id) => {
    return driverData.find((driver) => driver.drivers_id === primary_driver_id)
      .full_name;
  };

  return (
    <>
      <div className="background-color-full-dark display-flex">
        <div className="flex-column-no-ho-centered flex-basis-200px overflow-wrapper  ">
          {policies
            ? policies.map((policy) => (
                <div
                  className=" background-color-full-dark flex-column-no-ho-centered light-hover padding-5px border-bottom  "
                  onClick={() => {
                    fetchCoverages(policy.policy_type, policy.policy_id);
                    fetchTransactions(policy.policy_id);
                  }}
                >
                  <label
                    htmlFor="policy_id"
                    className="hover-cursor-pointer margin-bottom-10"
                  >
                    <b>Policy Number:</b> {policy.policy_id_two}
                  </label>
                  <label
                    htmlFor="policy_id"
                    className="hover-cursor-pointer margin-bottom-10"
                  >
                    <b>Policy Type:</b> {policy.policy_type}
                  </label>
                  <label
                    htmlFor="policy_id"
                    className="hover-cursor-pointer margin-bottom-10"
                  >
                    <b>Status:</b> {policy.status}
                  </label>
                  <label
                    htmlFor="policy_id"
                    className="hover-cursor-pointer margin-bottom-10"
                  >
                    <b>Effective Date:</b>{" "}
                    {new Date(policy.effective_date).toLocaleDateString(
                      "en-US",
                      { timeZone: "UTC" }
                    )}
                  </label>
                  <label
                    htmlFor="policy_id"
                    className="hover-cursor-pointer margin-bottom-10"
                  >
                    <b>Company:</b> {policy.company}
                  </label>
                  <label
                    htmlFor="policy_id"
                    className="hover-cursor-pointer margin-bottom-10"
                  >
                    <b>Total Premium:</b> ${policy.total_prem}
                  </label>
                  <label
                    htmlFor="policy_id"
                    className="hover-cursor-pointer margin-bottom-10"
                  >
                    <b>Date Bound:</b>{" "}
                    {new Date(policy.date_created).toLocaleDateString("en-US", {
                      timeZone: "UTC",
                    })}
                  </label>
                  <label
                    htmlFor="policy_id"
                    className="hover-cursor-pointer margin-bottom-10"
                  >
                    <b>Primary Driver:</b>{" "}
                    {driverData && policy.primary_driver_id
                      ? driverData.find(
                          (driver) =>
                            driver?.drivers_id === policy?.primary_driver_id
                        )?.full_name
                      : "No primary Driver"}
                  </label>
                   <label
                      className="hover-cursor-pointer margin-bottom-10"
                   >
                    <b>Sales Type:</b>{" "}
                    {policy.sales_type ? policy.sales_type : "Undefined"}
                   </label>
                      
                  {policy &&
                  policy.commissionable_premium !== null &&
                  policy.commissionable_premium !== 0 ? (
                    <label htmlFor="policy_id" className="hover-cursor-pointer margin-bottom-10">
                      <b>Commissionable Premium:</b> $
                      {policy.commissionable_premium}
                    </label>
                  ) : null}

                  {currentUserData && currentUserData[0].role === "Admin" ? (
                    <>
                      <label
                        htmlFor="policy_id"
                        className="hover-cursor-pointer color-red margin-bottom-10"
                        onClick={() => {
                          setToggleDelete(true);
                          setDeletePolicy(
                            () => () =>
                              deletePolicy(policy.policy_id, policy.policy_type, Cookies.get('currentHouseholdId'))
                          );
                        }}
                      >
                        🗑️Delete
                      </label>
                      <label
                        htmlFor="policy_id"
                        className="hover-cursor-pointer color-orange"
                        onClick={() => {
                          setPolicyData(policy);
                          setToggleEdit(true);
                        }}
                      >
                        🖊️Edit
                      </label>
                    </>
                  ) : null}

                  {toggleEdit && popups[7]}
                </div>
              ))
            : null}
        </div>
        <div className="flex-container-no-vert-center  overflow-wrapper flex-basis-400px">
          <h3 className="text-align-left color-orange"> </h3>
          <Coverages
            togglePopUpById={togglePopUpById}
            fetchedCoverages={fetchedCoverages}
            api={api}
            setCurrentCoveragePopup={setCurrentCoveragePopup}
          />
        </div>
        <div className="flex-basis-550px">
          {" "}
          {currentPolicyId && (
            <Transaction
              userIdAndFullName={userIdAndFullName}
              currentPolicyId={currentPolicyId}
              fetchTransactions={fetchTransactions}
              setCurrentCoveragePopup={setCurrentCoveragePopup}
              transactions={transactions}
              fetchData={fetchData}
            />
          )}
        </div>
      </div>
      {popups[currentCoveragePopup]}
      {toggleDelete && (
        <ConfirmDelete
          action={deleteThisPolicy}
          setToggleDelete={setToggleDelete}
        />
      )}
    </>
  );
}

export default Policies;
