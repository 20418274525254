import React, {useEffect, useState} from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Cookies from 'js-cookie';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar-styles.css';  // Assuming you have some custom styles
import { Toolbar } from 'react-big-calendar';
import Calender_popup from './calender_popup/Calender_popup';
const localizer = momentLocalizer(moment);


// Custom Toolbar Component
const CustomToolbar = (props) => {
  const { onView, onNavigate, label } = props;
  return (
    <>
    <div className = 'flex-across-space-between'>
      <div className="">
        <button onClick={() => onNavigate('PREV')} className='rbc-btn'>Prev</button>
        <button onClick={() => onNavigate('NEXT')} className='rbc-btn'>Next</button>
        <button onClick={() => onNavigate('TODAY')} className='rbc-btn'>This month</button>
        
        {/* Only include the views you want */}
    
      </div>
      <span>{label}</span>
    </div>
    </>
  );
};


function Calendar_page({tasks, getTasks, api}) {


  
  const [popupEventObj, setPopupEventObj] = useState([]);
  const [events, setEvents] = useState([]);
  const[togglePopup, setTogglePopup] = useState(false);
  const [household_data, setHouseholdData] = useState();

  useEffect(() => { 
    const fetchTasks = async () => {
      await getTasks();
      createEvents();
      
    };
    
    fetchTasks();
  }, [togglePopup]);


  const fetch_household = async(household_id) => { 
    const response = await fetch(`${api}/api/household?householdId=${household_id}`, { 
      headers: { 
        "Authorization": Cookies.get('jwt')
      }
    })
    return response;
  }
  

  const createEvents = () => {
    const newEvents = tasks.map((task) => ({
      id: task.task_id,
      is_completed: task.is_completed,
      title: task.task_desc,
      start: new Date(task.task_date   ),
      end: new Date(task.task_date  ), 
      household: task.household_id
        }));

    setEvents(newEvents);
  };

  const handleSelectEvent = (event, e) => {
    setTogglePopup(true)
    setPopupEventObj([event])
  };

  return (
    <div className='max-height'>
      <Calendar
        localizer={localizer}
        events={events}                 // Pass the events array to the Calendar
        startAccessor="start"           // Specifies the property that holds the start time of the event
        endAccessor="end"               // Specifies the property that holds the end time of the event
        style={{ height: 800 }}         // Set the height of the calendar
        defaultView="month"
        
        selectable={false} 
        components={{
          toolbar: CustomToolbar,  // Use your custom toolbar
        }}          // Set the default view to month
        onSelectEvent={(event, e) => handleSelectEvent(event, e)}
      />
      { togglePopup === true ? <Calender_popup  eventObj = {popupEventObj} setTogglePopup = {setTogglePopup}   api={api} getTasks ={getTasks} /> : null}
    </div>
  );
}

export default Calendar_page;
