import React from "react";
import DriverCard from "./DriverCard";
function TileDisplay({
  slide,
  driverData,
  setCurrentPopup,
  slideRight,
  slideLeft,
  togglePopUpByEl,
}) {
  return (
    <div className=" flex-across light-dark-container  height-n-300px      ">
      <div className={`  light-dark-container overflow-tile  `}>
        <div className={` flex-across  light-dark-container ${slide} `}>
          {driverData &&
            driverData.map((driver) => (
              <DriverCard
                middlename={driver.middlename}
                key={driver.drivers_id}
                id={driver.drivers_id}
                firstname={driver.firstname}
                lastname={driver.lastname}
                phone_number={driver.phone_number}
                relation={driver.relation}
                gender={driver.gender}
                dateOfBirth={driver.dateOfBirth}
                vehicleData={
                  driver.vehicle && driver.vehicle[0]
                    ? driver.vehicle
                    : "No car data"
                }
                slide={slide}
                togglePopUpByEl={togglePopUpByEl}
                setCurrentPopup={setCurrentPopup}
                primaryDriver={driver.primaryDriver}

                // Add other props as needed
              />
            ))}
        </div>
      </div>

      <div className="householdCrudOps light-dark-background flex-container-el ">
        <button
          type="button"
          className="popup-tab-buttons margin-left-10 hover-red margin-top-10px"
          onClick={() => setCurrentPopup(0)}
        >
          {" "}
          <b className="color-orange font-size-large hover-red"> + </b>
        </button>
        <button
          type="button"
          className="popup-tab-buttons margin-left-10 margin-top-10px hover-red"
        >
          {" "}
          <b
            className="color-orange font-size-large hover-red "
            onClick={() => slideRight()}
          >
            {" "}
            &lt;-{" "}
          </b>
        </button>
        <button
          type="button"
          className="popup-tab-buttons margin-left-10 margin-top-10px hover-red"
        >
          {" "}
          <b
            className="color-orange font-size-large hover-red "
            onClick={() => slideLeft()}
          >
            {" "}
            -&gt;{" "}
          </b>
        </button>
      </div>
    </div>
  );
}

export default TileDisplay;
