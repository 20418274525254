import React, { useEffect, useState, useRef } from "react";
import HouseholdController from "./middle_popups/HouseholdController";

import Cookies from "js-cookie";
import TabSection from "./tab-section";
import NewHouseHolds from "./middle_popups/NewHouseHolds";
import NewDrivers from "./middle_popups/NewDrivers";
import NewVehicles from "./middle_popups/NewVehicles";
import QuotePopup from "./middle_popups/QuotePopup";
import NewStatus from "./middle_popups/NewStatus";
import EditDriver from "./middle_popups/EditDriver";
import EditVehicle from "./middle_popups/EditVehicle";
import NewTasks from "./middle_popups/NewTasks";
import ChangeOrg from "./middle_popups/changeOrg";
import TileDisplay from "./tileDisplay";
import HouseholdInfo from "./middle_popups/HouseholdInfo";
import { useSelector, useDispatch } from "react-redux";
import { property, personal_auto, commercial } from "./redux/tileDisplayIndex";
import { postOrganization, getChildOrgs } from "./fetches/fetch";
import SelectPrimaryDriver from "./middle_popups/SelectPrimaryDriver";
import NewOrganization from "./middle_popups/NewOrganization";
const { getNextPrevHousehold, countCall, getRecentCustomers } = require("./fetches/fetch");
const { fetchQuotes, postQuotes } = require("./fetches/fetch.js");

function MiddleSide({
  getUwActions,
  currentUserData,
  api,
  allUserData,
  setCurrentHouseholdId,
  currentHouseholdId,
  userIdAndFullName,
  getTasks,
  setRecentCustomers,
  setSwitchNumber,
}) {
  const [assignedUsers, setAssignedUsers] = useState();
  const [householdData, setHouseholdData] = useState(null);
  const [driverData, setDriverData] = useState(null);
  const [vehicleData, setVehicleData] = useState([]);
  const [slide, setSlide] = useState(null);
  const mailing = useRef();
  const [activeComponentIndex, setActiveComponentIndex] = useState(null);
  const [parentHouseholdId, setParentHouseholdId] = useState(null);
  const [organizations, setOrganizations] = useState(null);
  const jwtToken = Cookies.get("jwt");
  const [toggleOrg, setToggleOrg] = useState(false);
  const [currentPopup, setCurrentPopup] = useState(null);
  const tileIndex = useSelector((state) => state.tileDisplayIndex.index);
  const [notes, setNotes] = useState();
  const [quotes, setQuotes] = useState();
  const dispatch = useDispatch();

  const fn = async () => {
    const response = await fetchQuotes(Cookies.get("currentHouseholdId"));
    const data = await response.json();
    setQuotes(data);
  };
  
  useEffect(() => { 
   const household_owner_user_ids =  householdData && householdData.user_ids ? JSON.parse(householdData.user_ids) : null; 
    setAssignedUsers(household_owner_user_ids)
  }, [householdData])

  const fetchNotes = async (api) => {
    const response = await fetch(
      `${api}/api/notes?household_id=${currentHouseholdId}`
    );
    const data = await response.json();
    setNotes(data);
  };

  const getVehicles = () => {
    if (jwtToken && currentHouseholdId) {
      fetch(`${api}/api/household/drivers?id=${currentHouseholdId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${jwtToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
       
          setActiveComponentIndex(null);
          //Json.stringfy returns empty [] so we check to see if the lengh if greeater than 2 characters to see if it is empty. Only set storage if the string is not empty.


          //Clear the state before loop
          setVehicleData([]);
          Promise.all(
            data.map((driver) => {
              return fetch(`${api}/api/driver/${driver.drivers_id}/vehicles`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `${jwtToken}`,
                },
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error("Network response was not ok");
                  }
                  return response.json();
                })
                .then((vehicleData) => {
                  //Clear prevState

                  //Vehicle Data contains an array in an array
                  if (vehicleData[0]?.length !== 0) {
                    //When only one driver has vehicles
                    if (vehicleData[0].length >= 2) {
                      //Add each object to the state which is an array because when multiple drivers have vehicles the VehicleData is seperate arrays/objs instead of one whole one.
                      vehicleData[0].forEach((vehicle) => {
                        setVehicleData((prevStates) => [
                          ...prevStates,
                          vehicle,
                        ]);
                      });
                    } else {
                      //when multiple drivers have vehicles
                      setVehicleData((prevStates) => [
                        ...prevStates,
                        vehicleData[0][0],
                      ]);
                    }
                  }

                  // Merge driver and vehicle data
                  return { ...driver, vehicle: vehicleData[0] };
                });
            })
          )
            .then((mergedData) => {
              // Update driverData with merged data
              setDriverData(mergedData);
            })
            .catch((error) => {
              console.error(
                "There was a problem with your fetch operation:",
                error
              );
            });
        })
        .catch((error) => {
          // Handle any errors
          console.error(
            "There was a problem with your fetch operation:",
            error
          );
        });
    } else {
      console.error("jwtToken or currentHouseholdId is missing");
    }
  };

  const setLatestHousehold = async () => {
    const response = await fetch(`${api}/api/household/latest?page=1`);
    const data = await response.json();
    Cookies.set("currentHouseholdId", data[0].household_id);
    setCurrentHouseholdId(data[0].household_id);
  };

  function formatPhoneNumber(phoneNumber) {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
    const formattedPhoneNumber = ` (${cleanedPhoneNumber.substring(
      0,
      3
    )}) (${cleanedPhoneNumber.substring(3, 6)}) (${cleanedPhoneNumber.substring(
      6
    )})`;
    return formattedPhoneNumber;
  }

  const updateDrivers = async (currentHouseholdId) => {
    fetch(`${api}/api/household/drivers?id=${currentHouseholdId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${jwtToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setDriverData(data);
      })
      .catch((error) => {
        // Handle any errors
        console.error("There was a problem with your fetch operation:", error);
      });
  };

  const togglePopUpByEl = (element) => {
    element.hidden = !element.hidden;
  };

  const getHouseholdData = async () => {
    if (jwtToken && currentHouseholdId) {
      fetch(
        `${api}/api/household?id=${currentHouseholdId}&user_id=${Cookies.get(
          "userid"
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${jwtToken}`,
          },
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setHouseholdData(data[0]);
          /*Sets the organization to view the commercial quote tile if household is an organization */
          if (data[0].organization && data[0].organization !== "null") {
            dispatch(commercial());
          } else {
            dispatch(personal_auto());
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error(
            "There was a problem with your fetch operation:",
            error
          );
        });
    } else {
      console.error("jwtToken or currentHouseholdId is missing");
    }
  };

  useEffect(() => {
    async function org_check() {
      await getHouseholdData();
    }
    org_check();
  }, [jwtToken, currentHouseholdId]);

  useEffect(() => {
    if (jwtToken && currentHouseholdId) {
      fetch(`${api}/api/household/drivers?id=${currentHouseholdId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${jwtToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setDriverData(data);
        })
        .catch((error) => {
          // Handle any errors
          console.error(
            "There was a problem with your fetch operation:",
            error
          );
        });
    } else {
      console.error("jwtToken or currentHouseholdId is missing");
    }
  }, [jwtToken, currentHouseholdId]);


  const handleGetRecentCustomers = async() => { 
    const response = await getRecentCustomers(Cookies.get('userid'));
    const data = await response.json();
    setRecentCustomers(data);
  }

  useEffect(() => {
    handleGetRecentCustomers();
    getVehicles();

    async function async() {
      await getUwActions(Cookies.get('userid'));
      const response = await getChildOrgs(Cookies.get("currentHouseholdId"));
      const data = await response.json();

      setOrganizations(data);
    }
    async();
  }, [jwtToken, currentHouseholdId]);

  const changeHousehold = async (action, household_id) => {
    let user_id = null;
    if (currentUserData[0]?.role === "Agent") {
      user_id = currentUserData[0].id;
    }
    const response = await getNextPrevHousehold(action, household_id, user_id);
    const data = await response.json();
    if (data && data[0]?.household_id) {
      Cookies.set("currentHouseholdId", data[0]?.household_id);
      setCurrentHouseholdId(data[0]?.household_id);
    }
  };

  const assignUser = async (user_id) => {
    let id = user_id.value;
    const response = await fetch(
      `${api}/api/household/assign?user_id=${id}&household_id=${currentHouseholdId}`,
      {
        method: "POST",
        headers: {
          Authorization: jwtToken,
        },
      }
    );

    if (!response.ok) {
      alert("Error assigning user");
    } else {
      setCurrentHouseholdId(Cookies.get("currentHouseholdId"));
    }
  };

  const slideLeft = () => {
    setSlide("slide-left");
  };

  const slideRight = () => {
    setSlide("slide-right");
  };

  const currentCustomer = [
    "New",
    "Quoted",
    "Quoted Hot",
    "Called, No Contact",
    "Called, LVM",
    "BAD NUMBER",
    "Do Not Call",
    "Not Elegible",
    "Price is too High",
    "Cancelled",
    "Terminated",
    "Try again in 6 months",
    "Closed",
    "Policy In Force",
    "Already With Allstate",
    "Follow Up/Ready to Close",
  ];

  const prospect = [
    "New",
    "Quoted",
    "Quoted Hot",
    "Called, No Contact",
    "Called, LVM",
    "BAD NUMBER",
    "Do Not Call",
    "Not Elegible",
    "Price is too High",
    "Contacted  Quoted & Call Back",
    "Not Interested",
    "Try again in 6 months",
    "Followed Up",
    "Already With Allstate",
    "Follow Up/Ready to Close",
  ];

  const formerCustomer = ["New", "Cancelled", "Terminated"];

  const updateStatusDescription = async (desc) => {
    let status_desc = desc.value;
    //If these criterias are met the agents must submit a quote to the notes.
    if (
      status_desc === "Quoted" ||
      status_desc === "Quoted Hot" ||
      status_desc === "Price is too High"
    ) {
      const response = await fetch(
        `${api}/api/household/status?household_id=${currentHouseholdId}`,
        {
          method: "POST",
          body: JSON.stringify({ status_description: status_desc }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        alert("Error updating status description");
      } else {
        //When the response comes back ok display the quotes form
        setCurrentPopup(5);
      }
    } else {
      //For when they dont select one of the criterias to require quoting.
      const response = await fetch(
        `${api}/api/household/status?household_id=${currentHouseholdId}`,
        {
          method: "POST",
          body: JSON.stringify({ status_description: status_desc }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        alert("Error updating status description");
      } else {
        await getHouseholdData();
      }
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };
  
  const handleOrgChange = async (e) => {
    if (e.target.value === "add") {
      setParentHouseholdId(Cookies.get("currentHouseholdId"));
      return setCurrentPopup(12);
    }
    Cookies.set("currentHouseholdId", e.target.value);
    setCurrentHouseholdId(e.target.value);
    setSwitchNumber(0);
  };

  const tileOptions = ["Personal Auto", "Property", "Commercial"];

  const handleCall = async () => {
    const reponse = await countCall(Cookies.get("userid"));
  };

  const popups = [
    <HouseholdController setCurrentPopup={setCurrentPopup} />, //Household Controller
    <NewHouseHolds
      setActiveComponentIndex={setActiveComponentIndex}
      setCurrentPopup={setCurrentPopup}
      api={api}
      setLatestHousehold={setLatestHousehold}
      parentHouseholdId={parentHouseholdId}
      setParentHouseholdId={setParentHouseholdId}
    />, //Adding new household
    <NewDrivers
      driverData={driverData}
      setCurrentPopup={setCurrentPopup}
      api={api}
      currentHouseholdId={currentHouseholdId}
      updateDrivers={updateDrivers}
      getVehicles={getVehicles}
    />, //Adding new driver
    <NewVehicles
      setCurrentPopup={setCurrentPopup}
      driverData={driverData}
      api={api}
      getVehicles={getVehicles}
    />, //Adding new vehicle. Passing driver data to display driver name when adding vehicle.
    <NewStatus
      api={api}
      currentHouseholdId={currentHouseholdId}
      setCurrentPopup={setCurrentPopup}
      householdData={householdData}
      currentCustomer={currentCustomer}
      prospect={prospect}
      formerCustomer={formerCustomer}
    />,
    <QuotePopup
      fn={fn}
      fetchNotes={fetchNotes}
      notes={notes}
      api={api}
      setCurrentPopup={setCurrentPopup}
      currentHouseholdId={currentHouseholdId}
      setSwitchNumber={setSwitchNumber}
      getHouseholdData={getHouseholdData}
      
    />,
    <EditDriver
      setCurrentPopup={setCurrentPopup}
      driverData={driverData}
      api={api}
      updateDrivers={updateDrivers}
      getVehicles={getVehicles}
    />,
    <EditVehicle
      setCurrentPopup={setCurrentPopup}
      driverData={driverData}
      api={api}
      vehicleData={vehicleData}
      getVehicles={getVehicles}
    />,
    <NewTasks
      setCurrentPopup={setCurrentPopup}
      api={api}
      getTasks={getTasks}
    />,
    <ChangeOrg setCurrentPopup={setCurrentPopup} />,
    <HouseholdInfo
      setCurrentPopup={setCurrentPopup}
      householdData={householdData}
      getHouseholdData={getHouseholdData}
    />,
    <SelectPrimaryDriver
      getVehicles={getVehicles}
      setCurrentPopup={setCurrentPopup}
      driverData={driverData}
      updateDrivers={updateDrivers}
    />,
    <NewOrganization
      setCurrentPopup={setCurrentPopup}
      api={api}
      setLatestHousehold={setLatestHousehold}
      parentHouseholdId={parentHouseholdId}
      setParentHouseholdId={setParentHouseholdId}
    />,
  ];

  /*Arr or props that will be passed into the different tile view*/
  const arrOfProps = [driverData, null, null];

  const handlePropIndexSelect = (index) => {
    switch (index) {
      case "Personal Auto":
        dispatch(personal_auto());
        break;
      case "Property":
        dispatch(property());
        break;
      case "Commercial":
        dispatch(commercial());
        break;
      default:
        dispatch(commercial());
    }
  };

  const handleToggleChangeAdressType = (e) => {
    if (e.target.value === "mailing") {
      return (mailing.current.innerText = "Mailing");
    }

    return (mailing.current.innerText = "Street");
  };

  return (
    <>
      {householdData && currentUserData && (
        <div className="middle-section-top light-dark-container  ">
          <div className=" text-align-left  align-items-center light-dark-container display-flex ">
            <h4 className="light-dark-container " id="household-status">
              {householdData.status + " "}
              <select
                className=" background-color-full-dark  margin-top-5px new-household-input padding-10px"
                id="top-level-status-desc"
                value={householdData.status_description}
                autocomplete="off"
                onChange={() =>
                  updateStatusDescription(
                    document.getElementById("top-level-status-desc")
                  )
                }
              >
                {householdData && householdData.status === "Current Customer"
                  ? currentCustomer.map((options) => (
                      <option value={options}>{options}</option>
                    ))
                  : householdData && householdData.status === "Prospect"
                  ? prospect.map((options) => (
                      <option value={options}>{options}</option>
                    ))
                  : householdData &&
                    householdData.status.toLowerCase() === "former customer"
                  ? formerCustomer.map((options) => (
                      <option value={options}>{options}</option>
                    ))
                  : null}
              </select>
            </h4>

            {householdData &&
              ((householdData && currentUserData[0]?.role === "Admin") ||
              currentUserData[0]?.role === "Supervisor" ? (
                <>
                <h4
                  className=" hover-red light-dark-container"
                  title="Assigned Already"
                >
                  Owners
                </h4>
                    <select className="background-color-full-dark padding-10px margin-top-5px new-household-input" >
                    {assignedUsers && assignedUsers.length > 0 ?  assignedUsers.map(user =>( 
                      <option className="light-dark-background">{userIdAndFullName[user.user_id]}</option> 
                    )):
                      <option className="light-dark-background">No Owner</option> 
                    }
                    </select>
                  </>
                 
            ) : null)}
           
            <select
              className="background-color-full-dark padding-10px margin-top-5px new-household-input"
              onClick={(e) => handlePropIndexSelect(e.target.value)}
            >
              <option value="" selected disabled>
                View: {tileOptions[tileIndex]}
              </option>
              {tileOptions.map((option, index) => (
                <option value={option}>{option}</option>
              ))}
            </select>
            <button
              type="button "
              className="green-submit-btn  "
              onClick={() => setCurrentPopup(8)}
            >
              New Task
            </button>
            <div className="padding-left-5px light-dark-background">
              <span class="light-dark-background ">
                Created at:{" "}
                {householdData?.date_created &&
                  new Date(householdData.date_created).toLocaleTimeString() +
                    " " +
                    new Date(householdData.date_created).toLocaleDateString()}
              </span>
            </div>
          </div>
          <div className="light-dark-background text-align-left padding-left-10px half-width ">
            <button
              className="green-submit-btn"
              onClick={() => setToggleOrg((prevState) => !prevState)}
            >
              ✔️
            </button>
            {toggleOrg === true ? (
              <select
                className="background-color-full-dark padding-10px  new-household-input"
                onChange={handleOrgChange}
              >
                <option default value={Cookies.get("currentHouseholdId")}>
                  Select Organization
                </option>
                {householdData && householdData.organization_household_id ? (
                  <option value={householdData.organization_household_id}>
                    Parent Household
                  </option>
                ) : null}
                {householdData &&
                householdData.organization_household_id === null ? (
                  <option value="add">Add Organization</option>
                ) : null}
                {organizations &&
                  organizations.map((org) => (
                    <option value={org.household_id}>
                      {org.organization
                        ? org.organization
                        : "No organization name specified"}
                    </option>
                  ))}
              </select>
            ) : null}{" "}
            {householdData.organization !== "null" ? (
              <span className="light-dark-background">
                {householdData.organization}
              </span>
            ) : null}
          </div> 
     
         <TileDisplay
            slide={slide}
            driverData={arrOfProps[tileIndex]}
            setCurrentPopup={setCurrentPopup}
            slideRight={slideRight}
            slideLeft={slideLeft}
            togglePopUpByEl={togglePopUpByEl}
          /> 
        
        </div> 
      )}

      {householdData ? (
        <div className="middle-section-middle light-dark-container margin-top-10px">
          <h3 className="light-dark-container">
            Household Contact Information:
          </h3>
          <div className="light-dark-container gird-container-household-contact">
            <label className="light-dark-container">
              <b
                className="light-dark-background color-orange cursor-pointer "
                title="Click To Copy"
                onClick={() => copyToClipboard(householdData.home_phone)}
              >
                Phone:
              </b>{" "}
              <a
                className="light-dark-container"
                href={"tel:" + householdData.home_phone}
                onClick={() => handleCall()}
              >
                {formatPhoneNumber(householdData.home_phone)}
              </a>
            </label>
            <label className="light-dark-container">
              <span
                className="light-dark-background "
                title="Click To Copy"
                onClick={() => copyToClipboard(householdData.email)}
              >
                <>
                  <b className="light-dark-background color-orange cursor-pointer ">
                    Email:
                  </b>{" "}
                  {householdData.email === "null" ? null : householdData.email}
                </>
              </span>
            </label>
            <label className="light-dark-container"></label>
            <label className="light-dark-container">
              <span
                className="light-dark-background "
                title="Click To Copy"
                onClick={() =>
                  copyToClipboard(householdData.status_description)
                }
              >
                {householdData.status_description !== "Policy In Force" &&
                householdData.status !== "Terminated" ? null : (
                  <>
                    <b className="light-dark-background color-orange cursor-pointer"></b>{" "}
                    {householdData.status_description}
                  </>
                )}
              </span>
            </label>
            <label className="light-dark-container">
              <b
                className="light-dark-background color-orange cursor-pointer"
                title="Click To Copy"
                ref={mailing}
                onClick={() => copyToClipboard(householdData.address_line_one)}
              >
                Street:
              </b>{" "}
              <select
                className="light-dark-background"
                onChange={handleToggleChangeAdressType}
              >
                <option value="street">{householdData.address_line_one}</option>
                <option value="mailing">{householdData.mailing_address}</option>
              </select>
            </label>
            <label className="light-dark-container">
              <b
                className="light-dark-background color-orange cursor-pointer"
                title="Click To Copy"
                onClick={() => copyToClipboard(householdData.city)}
              >
                City:
              </b>{" "}
              {householdData.city}
            </label>
            <label className="light-dark-container">
              <b
                className="light-dark-background color-orange cursor-pointer"
                title="Click To Copy"
                onClick={() => copyToClipboard(householdData.state)}
              >
                State:
              </b>{" "}
              {householdData.state}
            </label>
            <label className="light-dark-container">
              <b
                className="light-dark-background color-orange cursor-pointer"
                onClick={() => copyToClipboard(householdData.zip_code)}
                title="Click To Copy"
              >
                Zip Code:
              </b>{" "}
              {householdData.zip_code}
            </label>
          </div>
        </div>
      ) : (
        <div className="flex-center-column light-dark-container">
          <img
            className="light-dark-container ten-percent-width"
            src="https://i.gifer.com/ZKZg.gif"
          />
        </div>
      )}
      <div className="light-dark-container .flex-across household-control-btns ">
        <button
          type="button"
          className=" green-submit-btn"
          id="previous-household"
          onClick={() =>
            changeHousehold("prev", Cookies.get("currentHouseholdId"))
          }
        >
          Previous Household
        </button>
        {currentUserData && currentUserData[0]?.id == 2 ? (
          <label className="light-dark-container margin-right-20px">
            {" "}
            ID: {householdData?.household_id}
          </label>
        ) : null}
        <button
          type="button"
          className=" green-submit-btn"
          id="next-household"
          onClick={() =>
            changeHousehold("next", Cookies.get("currentHouseholdId"))
          }
        >
          Next Household
        </button>
      </div>

      <div className=" light-dark-container margin-top-10px ">
        <TabSection
          fn={fn}
          quotes={quotes}
          setQuotes={setQuotes}
          fetchNotes={fetchNotes}
          notes={notes}
          householdData={householdData}
          driverData={driverData}
          copyToClipboard={copyToClipboard}
          setCurrentHouseholdId={setCurrentHouseholdId}
          setSwitchNumber={setSwitchNumber}
          vehicleData={vehicleData}
          currentPopup={currentPopup}
          setCurrentPopup={setCurrentPopup}
          api={api}
          activeComponentIndex={activeComponentIndex}
          setActiveComponentIndex={setActiveComponentIndex}
          currentUserData={currentUserData}
          userIdAndFullName={userIdAndFullName}
          getHouseholdData = {getHouseholdData}
        />
      </div>

      {popups && popups[currentPopup]}
    </>
  );
}

export default MiddleSide;
